import { get as _get, has as _has } from 'lodash-es'

export default function errorHandler({ translationContext, i18n, data }) {
  let result = {
    inputErrors: {},
    formErrors: [],
  }

  if (_has(data, 'errors')) {
    result.inputErrors = Object.entries(data.errors).reduce((obj, error) => {
      obj[error[0]] = i18n.t(translationContext + error[1])
      return obj
    }, {})
  }

  return result
}

export const hasFormErrors = (errors) =>
  Object.values(errors).filter((el) => {
    if (typeof el === 'object') {
      return Object.values(el).length > 0
    }
    if (Array.isArray(el)) {
      return el.length > 0
    }
    return el !== false
  }).length > 0

const toFlatPropertyMap = (obj, keySeparator = '_') => {
  const flattenRecursive = (obj, parentProperty, propertyMap = {}) => {
    for (const [key, value] of Object.entries(obj)) {
      const property = parentProperty ? `${parentProperty}${keySeparator}${key}` : key
      if (value && typeof value === 'object') {
        flattenRecursive(value, property, propertyMap)
      } else {
        propertyMap[property] = value
      }
    }
    return propertyMap
  }
  return flattenRecursive(obj)
}

export const handleBackendFormError = (app, err, prefix = 'layout.errors') =>
  Object.entries(_get(err, 'response.data.errors', {})).reduce((obj, [k, v]) => {
    if (typeof v === 'object') {
      obj[k] = Object.entries(v).reduce((subObj, [subK, subV]) => {
        subObj[subK] = Object.entries(subV).reduce((errObj, [errK, errV]) => {
          errObj[errK] = app.i18n.te(`${prefix}.${errV}`) ? app.i18n.t(`${prefix}.${errV}`) : errV
          return errObj
        }, {})
        return subObj
      }, {})
    } else {
      obj[k] = app.i18n.te(`${prefix}.${v}`) ? app.i18n.t(`${prefix}.${v}`) : v
    }

    return toFlatPropertyMap(obj)
  }, {})

export const handleFormError = (app, errors, prefix = 'layout.errors') =>
  errors.reduce((obj, [k, v]) => {
    if (typeof v === 'object') {
      obj[k] = Object.entries(v).reduce((subObj, [subK, subV]) => {
        subObj[subK] = Object.entries(subV).reduce((errObj, [errK, errV]) => {
          errObj[errK] = app.i18n.te(`${prefix}.${errV}`) ? app.i18n.t(`${prefix}.${errV}`) : errV
          return errObj
        }, {})
        return subObj
      }, {})
    } else {
      obj[k] = app.i18n.te(`${prefix}.${v}`) ? app.i18n.t(`${prefix}.${v}`) : v
    }

    return toFlatPropertyMap(obj)
  }, {})

export const handleApiError = (app, err, prefix = 'layout.errors') => {
  const type = _get(err, 'response.data.type', null)
  const message = _get(err, 'response.data.message', null)
  const code = _get(err, 'response.data.code', null)

  if (app.i18n.te(`${prefix}.${type}`)) {
    return app.i18n.t(`${prefix}.${type}`)
  }

  console.log(`${prefix}.${message}`)

  if (app.i18n.te(`${prefix}.${message}`)) {
    return app.i18n.t(`${prefix}.${message}`)
  }

  return 'Une erreur est survenue, veuillez réessayer plus tard.'
}
