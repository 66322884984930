
import { nanoid } from 'nanoid/non-secure'
import { isNumber } from '@fabiospampinato/is'
import Teleport from 'vue2-teleport'
import { useFloating, autoUpdate, offset, flip, shift } from '@floating-ui/vue'
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap'
import {
  onClickOutside,
  useElementSize,
  watchDebounced,
  useEventListener,
  isDefined,
  watchOnce,
} from '@vueuse/core'

import { defineComponent, ref, computed, watch } from "@nuxtjs/composition-api";

export default defineComponent({
  components: {
    Teleport,
  },
  props: {
    tag: {
      type: String,
      default: 'span',
    },
    placement: {
      type: String,
      default: 'bottom-start',
    },
    maxHeight: {
      type: String,
      default: '',
    },
    offset: {
      type: Number,
      default: 8,
    },
    skidding: {
      type: Number,
      default: 0,
    },
    menuClasses: String,
    btnClasses: String,
    noAutofocus: Boolean,
    noAutoClose: Boolean,
    autoSize: {
      type: [Boolean, String],
      default: true,
    },
    shown: Boolean,
    width: [Number, String],
    block: Boolean,
    absolute: Boolean,
    wrap: Boolean,
    noPadding: Boolean,
    rounded: {
      type: Boolean,
      default: true,
    },
    manual: Boolean,
    teleport: {
      type: Boolean,
      default: true,
    },
    z: String,
  },
  setup(props, { emit }) {
    const id = ref(nanoid())
    const container = ref()
    const reference = ref()
    const floating = ref()

    const open = ref(false)
    const hasOpenMenu = ref(false)

    const isOpen = computed(() => open.value || hasOpenMenu.value)

    watchOnce(open, (val) => {
      if (val) emit('firstOpen', true)
    })

    const { floatingStyles, isPositioned, middlewareData } = useFloating(
      reference,
      floating,
      {
        whileElementsMounted: autoUpdate,
        placement: props.placement,
        middleware: [offset(props.offset), flip(), shift()],
      },
    )

    const { hasFocus, activate, deactivate } = useFocusTrap(floating)
    const { width: referenceWidth } = useElementSize(reference)

    const styles = computed(() => {
      let styles = {}

      if (isNumber(props.width)) styles.width = `${props.width}px`
      else if (typeof props.width === 'string') styles.width = props.width
      else if (props.autoSize === true) styles.minWidth = `${referenceWidth.value}px`
      else if (props.autoSize !== 'min') styles.width = `${referenceWidth.value}px`

      if (props.skidding !== 0) {
        switch (middlewareData.value.offset?.placement) {
          case 'bottom-center':
            styles.left = '50%'
            break
          case 'bottom-start':
          case 'bottom-end':
            styles.left = `${props.skidding}px`
            break

          case 'right-start':
          case 'left-start':
            styles.top = `${props.skidding}px`
            break
        }
      }

      return styles
    })

    onClickOutside(container, async ($event) => {
      if (open.value === true) {
        const maybeContainerParentModal = container.value.closest('.modal--container')
        const maybeEventOriginParentModal = $event.target.closest('.modal--container')
        /*
          Closes the floating menu if the event target clicked is not in another modal,
          is in the same modal as the menu or is inside the menu itself.
       */
        if (
          (maybeEventOriginParentModal === null ||
            maybeContainerParentModal === maybeEventOriginParentModal) &&
          !$event.target.closest('.floating--container')
        ) {
          open.value = false
          if (props.manual && !props.noAutoClose) {
            emit('close')
          }
        }
      }
    })

    onClickOutside(floating, async ($event) => {
      deactivate()
    })

    useEventListener(reference, 'keydown', async ($event) => {
      if ($event.key === 'Tab' && isPositioned.value && open.value) {
        await nextTick()
        activate()
      }
    })

    watchDebounced(
      open,
      async (val) => {
        hasOpenMenu.value = val
      },
      { debounce: 200 },
    )

    watch(open, (val) => {
      if (!val) deactivate()
    })

    watch(
      () => props.shown,
      (val) => {
        open.value = val
      },
    )

    function close() {
      open.value = false
    }

    function handleClick($event) {
      if (
        !props.manual &&
        ($event.target.closest(`#btn-${id.value}`) || $event.target.id === `#btn-${id.value}`)
      )
        open.value = !open.value
    }

    // Always opens over  its parent modal index
    const inferredZIndex = ref({})
    watch(open, (val) => {
      if (val) {
        if (container.value) {
          const closestModal = container.value.closest('.modal--container')
          if (closestModal) {
            let maybeZ = getComputedStyle(closestModal).zIndex
            if (maybeZ) inferredZIndex.value = { zIndex: `${Number(maybeZ)} !important` }
          }
        }
      }
    })

    return { id, container, reference, floating, open, isOpen, close, floatingStyles, styles, inferredZIndex, handleClick }

  }
})
