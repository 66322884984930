//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, provide, nextTick, computed, ref } from '@nuxtjs/composition-api'

import { useUser } from '@/composables/user'
import useSidebar from '@/composables/sidebar'
import { get as _get } from 'lodash-es'
import { withQuery } from 'ufo'
import useMq from '@/composables/mq'
export default defineComponent({
  props: {
    sidebarOpen: Boolean,
  },
  setup(props, { emit }) {
    const versionNumber = '1.11.24'
    const { sidebar } = useSidebar()
    const { user, company, subscription, can, isRoot } = useUser()
    const { mq } = useMq()
    const isStarter = computed(() => _get(subscription.value, 'plan.slug', null) === 'starter')

    const sidebarMap = computed(() =>
      sidebar.value
        .reduce((arr, el) => {
          arr = [].concat(arr, el.items)
          return arr
        }, [])
        .reduce((obj, val) => {
          if (val.items) {
            for (const child of val.items) {
              obj[child.key] = val.key
            }
          }
          return obj
        }, {})
    )

    const handleClickOutside = async ($event) => {
      if ($event.target.closest('.v-popper__popper') === null) {
        await nextTick()
        if (props.sidebarOpen) {
          emit('closeSidebar')
        }
      }
    }

    const switchCompany = (company) => {
      window.location.href = withQuery(window.location.href.replace('?invites=review', ''), {
        switch_company: company.id,
      })
    }

    provide('sidebarMap', sidebarMap)

    const companies = computed(() => _get(user.value, 'companies', []))

    const companyName = ref(null)

    const hasEllipsis = computed(() => {
      if (companyName.value) {
        return companyName.value.offsetWidth < companyName.value.scrollWidth
      }
      return false
    })

    return {
      props,
      versionNumber,
      company,
      companies,
      companyName,
      hasEllipsis,
      user,
      isStarter,
      sidebar,
      switchCompany,
      handleClickOutside,
    }
  },
})
