var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"inline-flex items-center text-sm text-gray-500"},[_vm._t("false",null,{"active":!_vm.innerValue,"innerValue":_vm.innerValue,"selectValue":_vm.selectValue}),_vm._v(" "),(_vm.label)?_c('span',{staticClass:"formulate-input-label text-gray-700 cursor-pointer",on:{"click":function($event){_vm.innerValue = !_vm.innerValue}}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_vm._v(" "),_c('button',{staticClass:"relative inline-flex shrink-0 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none",class:[
      _vm.notAllowed ? 'cursor-not-allowed' : 'cursor-pointer',
      _vm.noMargins ? '' : 'mx-1',
      _vm.size === 'sm' ? 'h-5 w-9' : 'h-6 w-11',
      _vm.innerValue ? 'bg-brand-500' : 'bg-gray-200',
      _vm.$slots.default && !_vm.label ? 'mr-2' : '',
      _vm.label ? 'ml-auto' : '' ],attrs:{"type":"button","role":"switch","aria-checked":"false"},on:{"click":function($event){return _vm.selectValue(!_vm.innerValue)}}},[_c('span',{staticClass:"sr-only"},[_vm._t("description")],2),_vm._v(" "),_c('span',{staticClass:"pointer-events-none inline-block rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200",class:[
        _vm.innerValue ? (_vm.size === 'sm' ? 'translate-x-4' : 'translate-x-5') : 'translate-x-0',
        _vm.size === 'sm' ? 'h-4 w-4' : 'h-5 w-5' ],attrs:{"aria-hidden":"true"}},[(_vm.loading)?_c('fa-icon',{attrs:{"icon":['far', 'spinner-third'],"spin":""}}):_vm._t("inner",null,{"active":_vm.innerValue})],2)]),_vm._v(" "),_vm._t("true",null,{"active":_vm.innerValue}),_vm._v(" "),(_vm.$slots.default)?_c('span',{staticClass:"cursor-pointer hover:text-gray-600",on:{"click":function($event){return _vm.selectValue(!_vm.innerValue)}}},[_vm._t("default")],2):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }