var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.noNavigation || !_vm.$auth.can('role', 'projects.read') ? 'span' : 'NuxtLink',{tag:"component",staticClass:"text-xs inline-block",attrs:{"to":_vm.noNavigation || !_vm.$auth.can('role', 'projects.read') ? '' : ("/projects/" + (_vm.project.id))}},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(!_vm.noTooltip && _vm.hasEllipsis ? _vm.project.name : null),expression:"!noTooltip && hasEllipsis ? project.name : null"}],staticClass:"cursor-pointer max-w-full inline-flex items-center border leading-[14px] rounded-full pr-2 group-hover:border-gray-300",class:[
      _vm.noIcon ? (_vm.deletable ? 'pl-2 py-1' : 'pl-2 py-1.5') : 'pl-px py-px',
      _vm.deletable ? 'pl-3' : '',
      _vm.colorize ? 'text-white' : 'border-gray-100 bg-gray-100 !text-gray-500',
      _vm.noNavigation || !_vm.$auth.can('role', 'projects.read') ? '' : ' hover:bg-gray-200 hover:!text-gray-600' ],style:({
      backgroundColor: _vm.colorize ? _vm.projectColor : undefined,
      borderColor: _vm.colorize ? _vm.projectColor : undefined,
    })},[(!_vm.noIcon)?_c('div',{staticClass:"flex shrink-0 items-center justify-center bg-orange-500 text-white rounded-full text-white text-xs",class:[_vm.xs ? 'w-4 h-4 text-[10px]  mr-1' : 'w-6 h-6 text-xs mr-2']},[_c('fa-icon',{attrs:{"icon":['fad', 'person-digging']}})],1):_vm._e(),_vm._v(" "),_c('span',{ref:"projectName",staticClass:"truncate",class:_vm.maxWidth},[_vm._v(" "+_vm._s(_vm.project.name))]),_vm._v(" "),(_vm.deletable)?_c('button',{staticClass:"text-sm ml-1 -mr-1 rounded-full w-5 h-5 bg-transparent flex items-center justify-center",class:[_vm.colorize ? 'hover:bg-black/20' : 'hover:bg-gray-200'],attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('delete')}}},[_c('fa-icon',{attrs:{"icon":['far', 'xmark']}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }