export const localeData = {
  direction: 'ltr',
  format: 'dd/mm/yyyy',
  separator: ' - ',
  applyLabel: 'Appliquer',
  cancelLabel: 'Annuler',
  weekLabel: 'S',
  customRangeLabel: 'Personnalisé',
  daysOfWeek: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
  monthNames: [
    'Janvier',
    'Fevrier',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Decembre',
  ],
  firstDay: 1,
}

export const getCountryLocale = (country) => {
  switch (country) {
    case 'fr':
      return 'fr-FR'
    case 'be':
      return 'fr-BE'
    case 'ch':
      return 'de-CH'
    default:
      return 'fr-FR'
  }
}

export const availableCurrencies = {
  eur: { value: 'EUR', label: 'Euro (€)', symbol: '€' },
  chf: { value: 'CHF', label: 'Franc Suisse (CHF)', symbol: 'CHF' },
  xpf: { value: 'F', label: 'Franc CFP', symbol: 'FCFP' },
  xaf: { value: 'F', label: 'Franc CFA', symbol: 'FCFA' },
  xof: { value: 'F', label: 'Franc CFA', symbol: 'FCFA' },
  mga: { value: 'A', label: 'Ariary', symbol: 'MGA' },
  cdf: { value: 'F', label: 'Franc congolais', symbol: 'FC' },
  usd: { value: 'USD', label: 'USD ($)', symbol: 'USD' },
}
