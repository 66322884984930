//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { kebabCase } from 'scule'
import { defineComponent, useContext, ref, computed, useRouter } from '@nuxtjs/composition-api'
import { useLoading } from '@/composables/loading'

import {
  CREDIT,
  DOCUMENT_STATUS_DRAFT,
  DOCUMENT_STATUS_OPEN,
  DOCUMENT_STATUS_REFUSED,
  DOCUMENT_STATUS_ACCEPTED,
  QUOTE,
  INVOICE,
  ORDER_FORM,
  DOCUMENT_STATUS_CANCELLED,
  DOCUMENT_SUBTYPE_FINAL,
  WORK_ORDER,
} from '@/constants/documents'

import { pluralizeType } from '@/api/resources'

import { useDocumentActions } from '@/composables/document'

import { useUser } from '@/composables/user'

export default defineComponent({
  props: {
    document: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const router = useRouter()
    const { can } = useUser()
    const { isLoading, setLoading } = useLoading()
    const { $getRepository } = useContext()
    const { duplicate, archive } = useDocumentActions($getRepository(props.document), emit)
    const isOpen = ref(false)

    const deleteModal = ref(null)

    const pluralType = pluralizeType[props.document.object]

    const allowDuplicate = computed(() => {
      if (!can('role', `${pluralType}.write`)) return false
      if (props.document.legacy) return false
      switch (props.document.objectType) {
        case QUOTE:
        case ORDER_FORM:
        case WORK_ORDER:
          return true
        case INVOICE:
          if ([null, DOCUMENT_SUBTYPE_FINAL].includes(props.document.subtype)) {
            return true
          }
      }

      return false
    })

    const allowDelete = computed(() => {
      if (!can('role', `${pluralType}.write`)) return false
      if (props.document.legacy) return true

      switch (props.document.objectType) {
        case QUOTE:
        case ORDER_FORM:
          return [
            DOCUMENT_STATUS_DRAFT,
            DOCUMENT_STATUS_OPEN,
            DOCUMENT_STATUS_REFUSED,
            DOCUMENT_STATUS_CANCELLED,
          ].includes(props.document.status)
        case WORK_ORDER:
          return true
        case CREDIT:
        case INVOICE:
          return [DOCUMENT_STATUS_DRAFT].includes(props.document.status)
        default:
          return false
      }
    })

    const viewDocument = async () => {
      switch (props.document.objectType) {
        case CREDIT:
          await router.push(`/credits/${props.document.id}/details`)
          break
        case QUOTE:
        case WORK_ORDER:
        case INVOICE:
        case ORDER_FORM:
          await router.push(
            `/${kebabCase(pluralizeType[props.document.objectType])}/${props.document.id}${
              props.document.status !== DOCUMENT_STATUS_DRAFT ? '/details' : ''
            }`
          )
          break
      }
    }

    const clientDetails = async () => {
      await router.push(`/contacts/${props.document.customer.id}`)
    }

    return {
      props,
      DOCUMENT_STATUS_REFUSED,
      DOCUMENT_STATUS_ACCEPTED,
      DOCUMENT_STATUS_CANCELLED,
      DOCUMENT_STATUS_DRAFT,
      DOCUMENT_STATUS_OPEN,
      WORK_ORDER,
      QUOTE,
      INVOICE,
      CREDIT,
      isLoading,
      isOpen,
      deleteModal,
      allowDelete,
      allowDuplicate,
      pluralType,
      can,
      clientDetails,
      viewDocument,
      duplicate,
      archive,
    }
  },
})
