//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  provide,
  ref,
  useContext,
} from '@nuxtjs/composition-api'
import useLayout from '@/composables/layout'
import useOnboarding from '@/composables/onboarding'
import { has as _has, debounce as _debounce, get as _get } from 'lodash-es'
import { useUser } from '@/composables/user'
export default defineComponent({
  setup() {
    if (_has(window, '$crisp')) {
      window.$crisp.push(['do', 'chat:hide'])
    }

    const { $bus } = useContext()
    const { user, company } = useUser()
    const layout = useLayout()
    const onboarding = useOnboarding()

    const bgClass = ref('bg-gray-50')

    const plansModal = ref(null)
    const bcModal = ref(null)
    const paymentModal = ref(null)
    const builderContainer = ref(null)
    const contactSidePanel = ref(null)
    const helpSidePanel = ref(null)
    const currentContact = ref(null)

    const openContact = async (contact) => {
      currentContact.value = contact.id
      await nextTick()
      contactSidePanel.value.openModal()
    }

    const openHelp = () => helpSidePanel.value.openModal()

    const openChat = () => {
      if (_has(window, '$crisp')) {
        window.$crisp.push(['do', 'chat:show'])

        window.$crisp.push(['set', 'user:email', [user.value.email]])

        if (_get(user.value, 'phone', null)) {
          window.$crisp.push(['set', 'user:phone', [user.value.phone]])
        }

        window.$crisp.push(['set', 'user:company', company.value.name])

        if (`${_get(user.value, 'firstName', '')} ${_get(user.value, 'lastName', '')}`.trim() !== '') {
          window.$crisp.push([
            'set',
            'user:nickname',
            [`${_get(user.value, 'firstName', '')} ${_get(user.value, 'lastName', '')}`],
          ])
        }

        window.$crisp.push(['do', 'chat:open'])
      }
    }

    const isDragging = ref(false)
    provide('isDragging', isDragging)
    provide('builderContainer', builderContainer)
    let timeout = null

    const handleDragOver = (event) => {
      event.preventDefault()
      isDragging.value = true
      clearTimeout(timeout)
      timeout = setTimeout(() => (isDragging.value = false), 1000)
    }

    onMounted(() => {
      $bus.on('scrollBuilderTop', () => {
        if (builderContainer.value) {
          builderContainer.value.scrollTop = 0
        }
      })
      $bus.on('openContact', (contact) => openContact(contact))
      $bus.on('openHelp', () => openHelp())
      $bus.on('openChat', () => openChat())
      $bus.on('openBcModal', () => bcModal.value.openModal())
      $bus.on('openPlans', () => plansModal.value.openModal())
      $bus.on('closePlans', () => plansModal.value.closeModal())

      window.document.addEventListener('dragover', handleDragOver, false)
    })

    onBeforeUnmount(() => {
      $bus.off('scrollBuilderTop')
      $bus.off('openContact')
      $bus.off('openHelp')
      $bus.off('openChat')
      $bus.off('openBcModal')
      $bus.off('openPlans')
      $bus.off('closePlans')

      window.document.removeEventListener('dragover', handleDragOver)
    })

    return {
      ...layout,
      ...onboarding,
      bgClass,
      currentContact,
      contactSidePanel,
      helpSidePanel,
      builderContainer,

      plansModal,
      bcModal,
    }
  },
})
