//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { nanoid } from 'nanoid/non-secure'
import { defineComponent, ref, onMounted, watch, useContext } from '@nuxtjs/composition-api'
import { useElementHover } from '@vueuse/core'
import { useErrorState, useLoading } from '@/composables/loading'

export default defineComponent({
  props: {},
  setup(props) {
    const btn = ref()
    const scrollContainer = ref()
    const isHovered = useElementHover(btn)
    const { setLoading, isLoading } = useLoading()
    const { setErrorState, hasErrorState } = useErrorState()
    const { $axios } = useContext()

    const formText = ref(null)
    const formAudioRecord = ref(null)

    const messages = ref([])

    watch(messages, (val) => {
      if (scrollContainer.value) {
        scrollContainer.value.scrollTop = scrollContainer.value.scrollHeight
      }
    })

    const sendMessage = async () => {
      const formData = new FormData()

      if (formAudioRecord.value) {
        messages.value.push({
          key: nanoid(),
          type: 'audio',
          data: formAudioRecord.value,
          url: window.URL.createObjectURL(formAudioRecord.value),
        })

        formData.append('file', formAudioRecord.value)
        formAudioRecord.value = null
        recording.value = false
      } else if (formText.value) {
        messages.value.push({
          key: nanoid(),
          type: 'text',
          data: formText.value,
        })

        formData.append('text', formText.value)
        formText.value = null
      }

      try {
        setLoading(true, 'ia_message')
        const { data } = await $axios.post('api/ai/chat', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: function (progressEvent) {
            uploadProgress.value = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
          },
        })

        if (data) {
          messages.value.push({
            key: nanoid(),
            type: 'text',
            data: data.data.message,
            source: 'agent',
          })
        }
      } catch (err) {
        setErrorState('ia_message')
      } finally {
        setLoading(false, 'ia_message')
      }
    }

    const recording = ref(false)

    const newRecording = () => {
      recording.value = true
    }

    onMounted(() => {
      if (scrollContainer.value) {
        scrollContainer.value.scrollTop = scrollContainer.value.scrollHeight
      }
    })

    const uploadProgress = ref(0)

    return {
      props,
      btn,
      scrollContainer,
      newRecording,
      sendMessage,
      formText,
      formAudioRecord,
      messages,
      recording,
      isHovered,
      stop,
      ref,
      isLoading,
      hasErrorState,
    }
  },
})
