//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, watch } from '@nuxtjs/composition-api'
import { rgbToHex, grays, colors, anyToHex } from '@/utils/palette'

export default defineComponent({
  props: {
    button: {
      type: Object,
      default: () => {},
    },
    editor: {
      type: Object,
      default: () => {},
    },
    context: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const getEditorColor = (color) => {
      return anyToHex(color)
    }

    const handleOpenDropdown = (open) => {
      emit('toggle', open)
    }

    return { props, colors, grays, anyToHex, getEditorColor, handleOpenDropdown }
  },
})
