import { render, staticRenderFns } from "./website.vue?vue&type=template&id=4f4f6b93&"
import script from "./website.vue?vue&type=script&lang=js&"
export * from "./website.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoginModal: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/LoginModal.vue').default,LibraryBCModal: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/library/BC/LibraryBCModal.vue').default,PlansModal: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/PlansModal.vue').default,PaymentModal: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/PaymentModal.vue').default,OnboardingModal: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/onboarding/OnboardingModal.vue').default,ContactSidePanel: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/contact/ContactSidePanel.vue').default,HelpSidePanel: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/HelpSidePanel.vue').default})
