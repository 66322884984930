//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'
export default defineComponent({
  props: {
    message: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    return { props }
  },
})
