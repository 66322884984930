import posthog from 'posthog-js'
import { get as _get } from 'lodash-es'
import { watch } from '@nuxtjs/composition-api'
export default (ctx, inject) => {
  watch(ctx.app.$auth.init, (val) => {
    if (val) {
      const user = _get(ctx.app, '$auth.user.value')
      posthog.init(ctx.env.POSTHOG_KEY, {
        api_host: ctx.env.POSTHOG_HOST,
        capture_pageview: false,
        loaded: () => {
          if (user.id) posthog.identify(user.id)
        },
      })
      console.log(user)
    }
  })

  inject('posthog', posthog)
}
