//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    ia: Boolean,
    toolbar: {
      type: Array,
      default: () => [],
    },
    editor: {
      type: Object,
      default: () => {},
    },
    toolbarClass: {
      type: String,
      default: '',
    },
    data: Object,
  },
  setup(props) {
    const expand = ref(false)
    return { props, expand }
  },
})
