import mitt from 'mitt'
import { get as _get } from 'lodash-es'
const emitter = mitt()
import { useEventListener } from '@vueuse/core'

export default function (ctx, inject) {
  inject('bus', emitter)

  /*inject('bus', {
    emit: (name, data) => {
      const event = new CustomEvent(name, { detail: data })
      window.document.dispatchEvent(event)
    },
    on: (name, fn) => {
      return useEventListener(window.document, name, (e) => {
        fn(e.detail)
      })
    },
    off: (val) => {
      if (typeof _get(val, 'value') === 'function') {
        val.value()
      }
    },
  })*/
}
