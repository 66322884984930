var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center justify-end pr-2 no-navigation"},[(
      _vm.document.objectType === _vm.INVOICE &&
      ![_vm.DOCUMENT_STATUS_REFUSED, _vm.DOCUMENT_STATUS_DRAFT, _vm.DOCUMENT_STATUS_CANCELLED].includes(_vm.document.status)
    )?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.document.paidAt ? ("Réglée le " + (_vm.$options.filters.humanDate(_vm.document.paidAt))) : 'Non réglée'),expression:"document.paidAt ? `Réglée le ${$options.filters.humanDate(document.paidAt)}` : 'Non réglée'"}],staticClass:"w-6 h-6 flex justify-center items-center justify-center rounded-full mr-2 translate-x-[2px]",class:{
      'text-green-500 bg-green-100': _vm.document.paidAt,
      'text-gray-400 bg-transparent': !_vm.document.paidAt,
    }},[_c('fa-icon',{attrs:{"icon":['far', 'coins']}})],1):_vm._e(),_vm._v(" "),(
      !_vm.document.legacy &&
      _vm.document.customer &&
      ![_vm.DOCUMENT_STATUS_REFUSED, _vm.DOCUMENT_STATUS_DRAFT].includes(_vm.document.status)
    )?_c('SendDocumentDropdown',{class:{
      'w-6 flex justify-center mr-1': _vm.document.objectType === _vm.QUOTE,
      'w-6 flex justify-center ml-px mr-[3px]': _vm.document.objectType === _vm.INVOICE,
      'w-6 flex justify-center ml-px mr-[2px]': _vm.document.objectType === _vm.CREDIT,
    },attrs:{"document":_vm.document,"icon-size":"small"},on:{"toggle":function($event){_vm.isOpen = $event}},scopedSlots:_vm._u([{key:"buttonTemplate",fn:function(){return [_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
          _vm.$t(("documents.label." + (_vm.document.sentAt ? 'sentAt' : 'send')), {
            date: _vm.$options.filters.humanDate(_vm.document.sentAt),
          })
        ),expression:"\n          $t(`documents.label.${document.sentAt ? 'sentAt' : 'send'}`, {\n            date: $options.filters.humanDate(document.sentAt),\n          })\n        "}],staticClass:"flex w-6 h-6 items-center justify-center rounded-full cursor-pointer",class:{
          'text-green-500 bg-green-100': _vm.document.sentAt,
          'text-gray-400 bg-transparent': !_vm.document.sentAt,
        },on:{"click":function($event){return _vm.toggle()}}},[_c('fa-icon',{attrs:{"icon":['far', 'envelope']}})],1)]},proxy:true}],null,false,542118611)}):_vm._e(),_vm._v(" "),(
      _vm.$auth.can('role', 'invoices.write') &&
      _vm.document.objectType === _vm.QUOTE &&
      [_vm.DOCUMENT_STATUS_OPEN, _vm.DOCUMENT_STATUS_ACCEPTED].includes(_vm.document.status)
    )?_c('span',{staticClass:"w-7 text-gray-400 flex items-center justify-center -mr-px border-r border-transparent"},[_c('fa-icon',{attrs:{"icon":['fal', 'receipt']}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"item-actions relative"},[_c('button',{staticClass:"w-6 py-1 px-2 focus:outline-none cursor-default border border-transparent"},[_c('fa-icon',{attrs:{"icon":['far', 'ellipsis']}})],1),_vm._v(" "),_c('div',{staticClass:"absolute flex bottom-0 top-0 right-full translate-x-8 whitespace-nowrap group-hover:block item-actions-open transition duration-150 ease-in-out",class:{ active: _vm.isOpen, 'z-50': _vm.isOpen, 'z-40': !_vm.isOpen }},[_c('div',{staticClass:"bg-white rounded-md border border-gray-200 shadow-md shadow-gray-300/20 flex"},[(_vm.allowDelete)?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Supprimer'),expression:"'Supprimer'"}],staticClass:"px-3 py-1 border-r border-gray-200 rounded-l-md focus:outline-none !text-gray-500 hover:bg-red-500 hover:border-red-600 hover:!text-white",on:{"click":function($event){return _vm.deleteModal.openModal()}}},[_c('fa-icon',{attrs:{"icon":['far', 'trash-can']}})],1):_vm._e(),_vm._v(" "),(_vm.allowDuplicate)?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Dupliquer'),expression:"'Dupliquer'"}],staticClass:"px-3 py-1 border-r border-gray-200 focus:outline-none hover:bg-gray-50 !text-gray-500 hover:!text-gray-700",class:{
            'rounded-l-md': ![_vm.DOCUMENT_STATUS_DRAFT, _vm.DOCUMENT_STATUS_OPEN].includes(_vm.document.status),
          },on:{"click":function($event){return _vm.duplicate(_vm.document)}}},[_c('fa-icon',{attrs:{"icon":_vm.isLoading('duplicate') ? ['far', 'spinner-third'] : ['far', 'clone'],"spin":_vm.isLoading('duplicate')}})],1):_vm._e(),_vm._v(" "),(_vm.can('role', (_vm.pluralType + ".write")) && _vm.document.status === _vm.DOCUMENT_STATUS_DRAFT)?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Modifier'),expression:"'Modifier'"}],staticClass:"px-3 py-1 border-r border-gray-200 focus:outline-none hover:bg-gray-50 !text-gray-500 hover:!text-gray-700",class:{
            'rounded-l-md': !_vm.allowDuplicate && !_vm.allowDelete,
          },on:{"click":function($event){return _vm.$emit('edit')}}},[_c('fa-icon',{attrs:{"icon":['far', 'pen']}})],1):_vm._e(),_vm._v(" "),(
            !_vm.document.legacy &&
            _vm.document.objectType === _vm.INVOICE &&
            _vm.document.objectType !== _vm.CREDIT &&
            ![_vm.DOCUMENT_STATUS_REFUSED, _vm.DOCUMENT_STATUS_DRAFT, _vm.DOCUMENT_STATUS_CANCELLED].includes(_vm.document.status)
          )?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
            _vm.document.paidAt ? ("Réglée le " + (_vm.$options.filters.humanDate(_vm.document.paidAt))) : 'Enregistrer un règlement'
          ),expression:"\n            document.paidAt ? `Réglée le ${$options.filters.humanDate(document.paidAt)}` : 'Enregistrer un règlement'\n          "}],staticClass:"flex h-[28px] px-2 py-1 items-center justify-center cursor-pointer border-r border-gray-200",class:{
            'text-green-500 bg-green-100 hover:!text-green-600': _vm.document.paidAt,
            'text-gray-400 hover:!text-gray-700 hover:bg-gray-50 bg-transparent': !_vm.document.paidAt,
            'rounded-l-md': !_vm.allowDuplicate,
          },attrs:{"type":"button"},on:{"click":function($event){_vm.document.paidAt ? null : _vm.$bus.emit('addPayment', _vm.document)}}},[_c('fa-icon',{attrs:{"icon":['far', 'coins']}})],1):_vm._e(),_vm._v(" "),(
            !_vm.document.legacy &&
            _vm.document.customer &&
            ![_vm.DOCUMENT_STATUS_REFUSED, _vm.DOCUMENT_STATUS_DRAFT].includes(_vm.document.status)
          )?_c('SendDocumentDropdown',{attrs:{"document":_vm.document,"icon-size":"small"},on:{"toggle":function($event){_vm.isOpen = $event}},scopedSlots:_vm._u([{key:"buttonTemplate",fn:function(){return [_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                _vm.$t(("documents.label." + (_vm.document.sentAt ? 'sentAt' : 'send')), {
                  date: _vm.$options.filters.humanDate(_vm.document.sentAt),
                })
              ),expression:"\n                $t(`documents.label.${document.sentAt ? 'sentAt' : 'send'}`, {\n                  date: $options.filters.humanDate(document.sentAt),\n                })\n              "}],staticClass:"flex h-[28px] px-2 py-1 items-center justify-center cursor-pointer border-r border-gray-200",class:{
                'rounded-l-md': _vm.document.objectType === _vm.CREDIT,
                'text-green-500 bg-green-100 hover:!text-green-600': _vm.document.sentAt,
                'text-gray-500 hover:!text-gray-700 hover:bg-gray-50 bg-transparent': !_vm.document.sentAt,
              }},[_c('fa-icon',{attrs:{"icon":['far', 'envelope']}})],1)]},proxy:true}],null,false,807234210)}):_vm._e(),_vm._v(" "),(
            _vm.$auth.can('role', 'invoices.write') &&
            _vm.document.objectType === _vm.QUOTE &&
            [_vm.DOCUMENT_STATUS_OPEN, _vm.DOCUMENT_STATUS_ACCEPTED].includes(_vm.document.status)
          )?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Facturer'),expression:"'Facturer'"}],staticClass:"w-7 flex items-center justify-center py-1 border-r border-gray-200 focus:outline-none hover:bg-gray-50 !text-gray-500 hover:!text-gray-700",on:{"click":function($event){return _vm.$bus.emit('toggleCharge', _vm.document)}}},[_c('fa-icon',{attrs:{"icon":['fal', 'receipt']}})],1):_vm._e(),_vm._v(" "),_c('VDropdown',{attrs:{"placement":"bottom-end","triggers":['click'],"positioning-disabled":['xs', 'sm'].includes(_vm.$mq)},on:{"show":function($event){_vm.isOpen = true},"hide":function($event){_vm.isOpen = false}},scopedSlots:_vm._u([{key:"popper",fn:function(ref){
          var hide = ref.hide;
return [_c('div',{staticClass:"p-1"},[_c('DropdownItem',{attrs:{"icon":['fal', 'arrow-right']},on:{"clicked":function($event){_vm.viewDocument()
                  hide()}}},[_vm._v("\n                "+_vm._s(_vm.$t('layout.action.view')))]),_vm._v(" "),(
                  [_vm.INVOICE, _vm.QUOTE, _vm.WORK_ORDER, _vm.CREDIT].includes(_vm.document.objectType) &&
                  _vm.$auth.can('role', (_vm.pluralType + ".write"))
                )?[(_vm.document.archived)?_c('DropdownItem',{attrs:{"icon":['fal', 'box-open'],"loading":_vm.isLoading(("unarchive_document_" + (_vm.document.id)))},on:{"clicked":function($event){return _vm.archive(false, hide)}}},[_vm._v("Désarchiver")]):_c('DropdownItem',{attrs:{"icon":['fal', 'box'],"loading":_vm.isLoading(("archive_document_" + (_vm.document.id)))},on:{"clicked":function($event){_vm.$auth.can('plan', 'documents.archives') ? _vm.archive(_vm.document, true, hide) : _vm.$bus.emit('openPlans')}}},[_vm._v("Archiver")])]:_vm._e()],2)]}}])},[_c('button',{staticClass:"ml-px py-1 pl-2 pr-2.5 focus:outline-none cursor-pointer -translate-x-0.5 -mr-0.5 !text-gray-500 hover:!text-gray-700",class:{ 'border-l border-gray-200 hover:bg-gray-50': _vm.allowDuplicate || _vm.allowDelete }},[_c('fa-icon',{attrs:{"icon":['far', 'ellipsis']}})],1)])],1)]),_vm._v(" "),_c('DeleteModal',{ref:"deleteModal",attrs:{"object":_vm.document}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }