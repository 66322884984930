import { render, staticRenderFns } from "./AiAgent.vue?vue&type=template&id=4f425e6a&"
import script from "./AiAgent.vue?vue&type=script&lang=js&"
export * from "./AiAgent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/Button.vue').default,AiMessage: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/Ai/AiMessage.vue').default,AudioBlock: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/Audio/AudioBlock.vue').default,ResizeableTextarea: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/layout/ResizeableTextarea.vue').default,Floating: require('/home/bas/app_b2af9b4e-e430-471c-9e1d-8cc53ecaf463/components/Floating.vue').default})
