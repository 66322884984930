//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, onMounted, watch, ref } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: '',
    },
    noMargins: Boolean,
    notAllowed: Boolean,
    loading: Boolean,
  },
  setup(props, { emit }) {
    const innerValue = ref(false)

    watch(
      () => props.value,
      (val) => {
        innerValue.value = val
      }
    )

    onMounted(() => {
      if (props.value) {
        innerValue.value = props.value
      }
    })

    const selectValue = function ($event) {
      emit('input', $event)
    }

    return { props, innerValue, selectValue }
  },
})
