//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get as _get, has as _has, set as _set, cloneDeep as _cloneDeep } from 'lodash-es'
import { useFileDialog, useDropZone } from '@vueuse/core'
import {
  defineComponent,
  ref,
  useContext,
  useFetch,
  nextTick,
  watch,
  onMounted,
  onBeforeUnmount,
} from '@nuxtjs/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
const { useActions, useMutations, useGetters, useState } = createNamespacedHelpers('templates')
import { isLargeImage } from '@/utils/preview'
import useModal from '@/composables/modal'

import { getRandomString } from '@/utils/documents'
import { useInfiniteScroll } from '@/composables/infiniteScroll'
import { useLoading } from '@/composables/loading'

function imageSize(url) {
  const img = document.createElement('img')

  const promise = new Promise((resolve, reject) => {
    img.onload = () => {
      // Natural size is the actual image size regardless of rendering.
      // The 'normal' `width`/`height` are for the **rendered** size.
      const width = img.naturalWidth
      const height = img.naturalHeight

      // Resolve promise with the width and height
      resolve({ width, height })
    }

    // Reject promise on error
    img.onerror = reject
  })

  // Setting the source makes it start downloading and eventually call `onload`
  img.src = url

  return promise
}

export default defineComponent({
  props: {
    purpose: {
      type: [String, Array],
      default: '',
    },
    purposeCreate: String,
    types: {
      type: Array,
      default: null,
    },
    exclude: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { $filesRepository, $bus, $axios, app } = useContext()
    const modal = useModal()

    const deleteFile = ref(null)
    const deleteModal = ref(null)
    const selectedFile = ref(null)
    const selectedFiles = ref([])

    const triggerDeleteFile = async (file) => {
      deleteFile.value = file
      await nextTick()
      deleteModal.value.openModal()
    }

    const file = ref(null)
    const files = ref([])

    const fileError = ref(null)
    const { setLoading, isLoading } = useLoading()

    const { init, fetch, page, scrollContainer, handleMetadata, handleEventListener } = useInfiniteScroll(
      'load_files',
      async () => {
        setLoading(true, `load_files`)
        try {
          const { data, metadata } = await $filesRepository.list({
            params: {
              purpose: props.purpose,
              type: props.types || undefined,
              _limit: 30, // TODO maybe improve if 30 initial files aren't enough to display a scrollbar on certain screens
              _page: page.value,
              _sort: 'createdAt',
              _order: 'desc',
            },
          })

          handleMetadata(files.value, metadata)

          files.value = [].concat(files.value, data)
          init.value = true
        } catch (err) {
        } finally {
          setLoading(false, `load_files`)
        }
      }
    )

    const { setData } = useMutations(['setData'])

    const dropZone = ref()

    const { open, reset, onChange } = useFileDialog({
      accept: 'image/*',
    })

    const {
      open: openPhoto,
      reset: resetPhoto,
      onChange: onChangePhoto,
    } = useFileDialog({
      accept: 'image/*',
      capture: 'environment',
    })

    async function onDrop(uploadFiles) {
      const uploadedFile = _get(uploadFiles, '0', null)
      await handleImage(uploadedFile)
    }

    const { isOverDropZone } = useDropZone(dropZone, {
      onDrop,
      dataTypes: ['image/*'],
      multiple: false,
      preventDefaultForUnhandled: false,
    })

    onChange(async (uploadFiles) => {
      const uploadedFile = _get(uploadFiles, '0', null)
      await handleImage(uploadedFile)
    })

    onChangePhoto(async (uploadFiles) => {
      const uploadedFile = _get(uploadFiles, '0', null)
      await handleImage(uploadedFile)
    })

    const handleImage = async (uploadedFile) => {
      fileError.value = null

      if (uploadedFile) {
        const fileSize = uploadedFile.size / 1024 / 1024

        if (fileSize > 6) {
          fileError.value = app.i18n.t('gallery.error.fileSize')
          file.value = null
        }

        const formData = new FormData()
        formData.append('file', uploadedFile)
        formData.append('filename', uploadedFile.name)
        formData.append(
          'purpose',
          props.purposeCreate || (Array.isArray(props.purpose) ? _get(props.purpose, '0', null) : props.purpose)
        )

        const fileKey = getRandomString(4)
        const previewUrl = URL.createObjectURL(uploadedFile)

        if (fileError.value === null) {
          try {
            const imageSizes = await imageSize(previewUrl)

            files.value.unshift({
              key: fileKey,
              id: fileKey,
              url: previewUrl,
              progress: 1,
              width: imageSizes.width,
              height: imageSizes.height,
            })

            const uploadIndex = files.value.findIndex((el) => el.key === fileKey)
            selectedFile.value = { id: fileKey, filename: uploadedFile.name }

            const { data } = await $axios.post('api/files', formData, {
              progress: false,
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              onUploadProgress: function (progressEvent) {
                const progression = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))

                if (uploadIndex !== -1) {
                  _set(files.value, `${uploadIndex}.progress`, progression)
                }
              }.bind(this),
            })

            if (uploadIndex !== -1) {
              files.value.splice(uploadIndex, 1, { ...data.data, url: previewUrl })
            }

            selectedFile.value = data.data
          } catch (err) {
            const error = _get(err, 'response.data.data.error', null)
            const maxFileSize = _get(err, 'response.data.data.max_size', 6291456)

            if (error) {
              fileError.value = app.i18n.t(`gallery.error.${error}`, { size: maxFileSize })
            } else {
              const errors = _get(err, 'response.data.errors', null)
              if (errors) {
                fileError.value = Object.keys(errors)
                  .map((el) => app.i18n.t(`gallery.error.${el}`))
                  .join('<br />')
              }
            }

            const uploadIndex = files.value.findIndex((el) => el.key === fileKey)
            if (uploadIndex !== -1) {
              files.value.splice(uploadIndex, 1)
            }
          } finally {
            reset()
            resetPhoto()
          }
        }
      }
    }

    const useSelectedFile = async () => {
      emit('select', _cloneDeep(selectedFile.value))
      await modal.closeModal()
    }

    watch(
      () => modal.isModalOpen(),
      async (val) => {
        if (val) {
          init.value = false
          files.value = []
          page.value = 1
          fetch()
          selectedFile.value = null
        } else {
          emit('close')
        }
        await nextTick()
        handleEventListener(val)
      }
    )

    onMounted(() => {
      $bus.on('delete.item', ({ object, type }) => {
        const index = files.value.findIndex((el) => el.id === object.id)
        if (index !== -1) {
          files.value.splice(index, 1)
        }
      })
    })

    onBeforeUnmount(() => {
      $bus.off('delete.item')
    })

    return {
      ...modal,
      init,
      selectedFiles,
      files,
      selectedFile,
      file,
      fileError,
      scrollContainer,
      deleteFile,
      deleteModal,
      open,
      openPhoto,
      dropZone,
      isOverDropZone,
      triggerDeleteFile,
      isLoading,
      handleImage,
      useSelectedFile,
      isLargeImage,
    }
  },
})
