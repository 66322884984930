
import { defineComponent, nextTick, onBeforeUnmount, onMounted, useContext } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    autofocus: Boolean,
  },
  setup(props, { slots }) {
    const { $bus } = useContext()

    const resizeTextarea = async (forceHeight = null) => {
      if (forceHeight === null) {
        await nextTick()
      }

      const textarea = slots.default()[0].elm

      if (textarea) {
        textarea.setAttribute('style', 'height: 1px')
        let targetHeight = forceHeight || textarea.scrollHeight
        if (targetHeight > 40) {
          targetHeight -= 2
        }
        textarea.setAttribute('style', 'height:' + targetHeight + 'px;overflow-y:hidden;')
      }
    }

    onMounted(async () => {
      await resizeTextarea()

      const textarea = slots.default()[0].elm

      if (props.autofocus) {
        textarea.focus()
      }

      async function handleResize() {
        this.style.height = '1px'
        const targetHeight = this.scrollHeight
        this.style.height = targetHeight + 'px'
      }

      if (textarea) {
        textarea.addEventListener('input', handleResize)
        textarea.addEventListener('change', handleResize)
      }

      $bus.on('resizeTextarea', async () => {
        await resizeTextarea()
      })
    })

    onBeforeUnmount(() => {
      const textarea = slots.default()[0].elm

      function handleResize() {
        this.style.height = '1px'
        this.style.height = this.scrollHeight + 2 + 'px'
      }

      textarea.removeEventListener('input', handleResize)
      textarea.removeEventListener('change', handleResize)

      $bus.off('resizeTextarea')
    })

    return { resizeTextarea }
  },
  render() {
    return this.$slots.default[0]
  },
})
