//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, defineComponent, ref } from '@nuxtjs/composition-api'
import useEvents from '@/composables/events'
import { darken, lighten, isDark } from 'khroma'

export default defineComponent({
  props: {
    project: {
      type: Object,
      default: () => {},
    },
    xs: Boolean,
    noIcon: Boolean,
    colorize: Boolean,
    noNavigation: Boolean,
    noTooltip: Boolean,
    deletable: Boolean,
    maxWidth: {
      type: String,
      default: 'max-w-[10rem]',
    },
  },
  setup(props) {
    const { pickColor } = useEvents()
    const projectName = ref(null)

    const projectColor = computed(() => {
      let color = pickColor(props.project.id)

      if (isDark(color)) {
        //return lighten(color, 10)
      }
      return color
    })

    const projectDarkColor = computed(() => {
      let color = pickColor(props.project.id)
      return darken(color, 10)
    })

    const hasEllipsis = computed(() => {
      if (projectName.value) {
        return projectName.value.offsetWidth < projectName.value.scrollWidth
      }
      return false
    })
    return { props, pickColor, projectColor, projectDarkColor, projectName, hasEllipsis }
  },
})
