//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import useModal from '@/composables/modal'
import useAccounting from '@/composables/accounting'
import { useErrorState, useLoading } from '@/composables/loading'
import { useMargins, useWorks } from '@/composables/products'
import useHighlight from '@/composables/highlight'
import { convertSupplies, formatNumber, getRandomString } from '@/utils/documents'

import {
  PRODUCT_TYPE_MATERIAL,
  PRODUCT_TYPE_MISC,
  PRODUCT_TYPE_SUBCONTRACT,
  PRODUCT_TYPE_SUPPLY,
  PRODUCT_TYPE_WORK,
  PRODUCT_TYPE_WORK_DETAILED,
  PRODUCT_TYPES,
  PRODUCT_TYPES_CREATE,
  PRODUCT_TYPES_WORKS,
} from '@/constants/products'
import { CONTACT_TYPE_SUBCONTRACTOR, CONTACT_TYPE_SUPPLIER } from '@/constants/contacts'
import {
  computed,
  defineComponent,
  nextTick,
  provide,
  reactive,
  ref,
  useContext,
  useFetch,
  watch,
} from '@nuxtjs/composition-api'

import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { useUser } from '@/composables/user'
import { useToolbar } from '@/composables/editor'
import { hasFormErrors } from '@/utils/error-handler'
import { cloneDeep as _cloneDeep, get as _get, has as _has, pick as _pick } from 'lodash-es'
import { roundToTwo } from '@/utils/numbers'
import { nanoid } from 'nanoid/non-secure'
import { ACCOUNTING_MODE_DISABLED } from '@/constants/companies'

const { useGetters } = createNamespacedHelpers('documents')

export default defineComponent({
  props: {
    mode: {
      type: String,
      default: 'add',
    },
    product: {
      type: Object,
      default: () => {},
    },
    fetchId: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: PRODUCT_TYPE_SUPPLY,
    },
    category: Object,
  },
  setup(props, { emit }) {
    const modal = useModal()
    const { highlight } = useHighlight()
    const { setLoading, isLoading } = useLoading()
    const { setErrorState, hasErrorState } = useErrorState()
    const { app, $toast, $bus, $format, $productsRepository } = useContext()

    const { user, company, units, defaultUnit, defaultUnitObject, defaultTax, can, requiredPlan } = useUser()
    const { defaultWorkItem } = useWorks()

    const defaultForm = (params) => {
      let data = {
        type: params.type || PRODUCT_TYPE_SUPPLY,
        sellPriceLocked: params.sellPriceLocked || false,
        name: params.name || '',
        reference: params.reference || '',
        supplier: params.supplier || null,
        category: params.category || null,
        unit: params.defaultUnit || null,
        images: params.images || [],
        tax: params.defaultTax || null,
        buyPrice: params.buyPrice || null,
        sellPrice: params.sellPrice || null,
        sellPriceForced: params.sellPriceForced || false,
        stock: params.stock || 0,
        manageStock: params.manageStock || false,
        planItem: params.planItem || null,
      }

      return data
    }

    const minPlan = computed(() => requiredPlan('products.images'))

    const editorContent = ref(null)
    const productCategoryModal = ref(null)
    const searchCategories = ref(null)
    const createContactModal = ref(null)
    const createNextProduct = ref(false)

    const productMetadata = ref(null)
    const currentElementId = ref(null)
    const spreadModal = ref(null)
    const spreadCount = ref(0)

    const editElementModal = ref(null)
    const editElement = async (id) => {
      currentElementId.value = id
      await nextTick()
      if (editElementModal.value) {
        editElementModal.value.openModal()
      }
    }

    const selectedTab = ref('details')

    const grossMarginRate = ref(null)
    const grossMarginRateInput = ref(null)

    const formRef = ref(null)
    const form = reactive(defaultForm({ type: props.type, defaultUnit }))
    const formErrors = reactive({ name: false, sellPrice: false })

    const { getDefaultPlanItem, getPlanItemOptions } = useAccounting()
    const defaultPlanItem = computed(() => getDefaultPlanItem(form.type))
    const planItemOptions = computed(() => getPlanItemOptions(form.type))

    const hasLoaded = ref(false)

    const productId = ref(null)
    const fetchError = ref(null)
    const elements = ref([defaultWorkItem()])
    const margins = useMargins(form)

    const defaultMarginRate = computed(() =>
      _get(company.value, `defaultMargins.${form.type}`, _get(company.value, 'defaultMargin', 30))
    )

    const supplierType = computed(() => (form.type === PRODUCT_TYPE_SUBCONTRACT ? 'subcontractor' : 'supplier'))
    const supplierQueryParams = computed(() => ({
      type: form.type === PRODUCT_TYPE_SUBCONTRACT ? CONTACT_TYPE_SUBCONTRACTOR : CONTACT_TYPE_SUPPLIER,
    }))

    const buyPricePercent = computed(() => {
      const defaultCoefficient = _get(company.value, 'defaultCoefficient', 0)
      const buyPrice = _get(form, 'buyPrice', 0) * (1 + defaultCoefficient / 100)

      return (buyPrice / form.sellPrice) * 100
    })

    const costsPercent = computed(() => {
      const defaultCoefficient = _get(company.value, 'defaultCoefficient', 0)
      const buyPrice = _get(form, 'buyPrice', 0) * (1 + defaultCoefficient / 100)
      return ((margins.totalCost.value - form.buyPrice) / form.sellPrice) * 100
    })

    const { toolbar } = useToolbar([
      ['bold', 'italic', 'strike', 'underline', 'color', 'unsetAllMarks'],
      ['ul', 'ol', 'link'],
      ['clearNodes'],
    ])

    const showExtended = ref(false)

    function createProduct(multiple = false) {
      createNextProduct.value = multiple
      if (_has(formRef.value, 'formSubmitted')) {
        formRef.value.formSubmitted()
      }
    }

    const updateMargin = () => {
      if (form.buyPrice && form.sellPrice) {
        let grossMargin = form.sellPrice - form.buyPrice
        grossMarginRate.value = (grossMargin / form.buyPrice) * 100
      }
    }

    const applyMargin = (targetMarginRate) => {
      if (hasLoaded.value) {
        grossMarginRate.value = targetMarginRate
        if (!form.sellPriceLocked) {
          const buyPrice = _get(form, 'buyPrice', 0)
          if (buyPrice > 0) {
            const grossMargin = (buyPrice * targetMarginRate) / 100
            form.sellPrice = roundToTwo(buyPrice + grossMargin)
          }
        }
      }
    }

    const handleWorksSellPrice = ($event) => {
      if (!form.sellPriceForced) {
        form.sellPrice = $event / 100
      }
    }

    const handleWorksBuyPrice = ($event) => {
      form.buyPrice = $event / 100
    }

    watch(
      () => form.name,
      (val) => val && (formErrors.name = false)
    )

    watch(
      () => form.buyPrice,
      (val) => {
        if (hasLoaded.value === true) {
          if (form.buyPrice === null) {
            grossMarginRate.value = null
          } else {
            let rate = _get(company.value, `defaultMargins.${form.type}`, _get(company.value, 'defaultMargin', 30))

            if (form.sellPrice !== 0 && form.buyPrice !== 0) {
              grossMarginRate.value = rate
            }

            if (form.type !== PRODUCT_TYPE_WORK_DETAILED && form.sellPriceLocked === false) {
              const applyGrossMarginRate = roundToTwo((val * grossMarginRate.value) / 100)
              form.sellPrice = roundToTwo(val + applyGrossMarginRate)
            } else {
              updateMargin()
            }
          }
        }
      }
    )

    watch(
      () => form.type,
      (to, from) => {
        if (hasLoaded.value) {
          if (to === 'text' || from === 'text') {
            Object.assign(form, defaultForm({ name: form.name, type: to, defaultUnit }))
            elements.value = to === PRODUCT_TYPE_WORK_DETAILED ? [defaultWorkItem()] : [{ name: '' }]
          } else {
            if (PRODUCT_TYPES_WORKS.includes(from) && PRODUCT_TYPES_WORKS.includes(to)) {
              const { supplies, sellPrices, buyPrices } = convertSupplies(elements.value, {
                from,
                to,
                defaultUnit: defaultUnitObject,
              })

              if (to === PRODUCT_TYPE_WORK_DETAILED) {
                if (!form.sellPriceForced && sellPrices > 0) {
                  form.sellPrice = sellPrices / 100
                }

                if (buyPrices > 0) {
                  form.buyPrice = buyPrices / 100
                }
              }

              elements.value = supplies
            }

            if (!PRODUCT_TYPES_WORKS.includes(from) && PRODUCT_TYPES_WORKS.includes(to)) {
              elements.value = to === PRODUCT_TYPE_WORK_DETAILED ? [defaultWorkItem()] : [{ name: '' }]
            }
          }
        }
      }
    )

    watch(
      () => form.sellPriceForced,
      (val) => {
        if (val === false && form.type === PRODUCT_TYPE_WORK_DETAILED) {
          formErrors.sellPrice = null
          form.sellPrice =
            elements.value.reduce((total, item) => {
              if (item.element && item.element.sellPrice && item.element.name) {
                total += Math.round(item.element.sellPrice * item.quantity * 100) / 100
              }
              return total
            }, 0) / 100
        }
      }
    )

    watch(
      () => form.sellPrice,
      (val) => {
        if (hasLoaded.value && window.document.activeElement !== _get(grossMarginRateInput.value, '$el', null)) {
          updateMargin()
        }
      }
    )

    watch(
      () => form.manageStock,
      (val) => {
        if (val === true && form.stock === null) form.stock = 0
      }
    )

    watch(
      () => modal.isModalOpen(),
      async (val) => {
        if (val === true) {
          selectedTab.value = 'details'
          productMetadata.value = null
          resetForm()
          form.type = props.type

          productId.value = null
          form.id = undefined
          if (['update', 'duplicate'].includes(props.mode)) {
            let productData = {}

            // Getting passed product
            if (_has(props.product, 'id')) {
              if (props.mode !== 'duplicate') {
                productId.value = props.product.id
              }
              productData = _cloneDeep(props.product)
            }

            // Fetching product by its uuid
            if (props.fetchId !== null) {
              setLoading(true, `fetch_product_${props.fetchId}`)
              try {
                const { data, metadata } = await $productsRepository.find(props.fetchId, {
                  spread: 1,
                  _expand: ['images', 'category'],
                })
                if (props.mode !== 'duplicate') {
                  productId.value = props.fetchId
                }
                productMetadata.value = metadata
                productData = data
              } catch (err) {
                fetchError.value = err
              } finally {
                setLoading(false, `fetch_product_${props.fetchId}`)
              }
            }

            if (PRODUCT_TYPES_WORKS.includes(productData.type)) {
              elements.value = [].concat(
                productData.type === PRODUCT_TYPE_WORK_DETAILED ? productData.elements : productData.supplies
              )

              if (elements.value.length === 0) {
                elements.value = productData.type === PRODUCT_TYPE_WORK_DETAILED ? [defaultWorkItem()] : [{ name: '' }]
              }

              if (productData.type === PRODUCT_TYPE_WORK) {
                if (_get(elements.value, `${elements.value.length - 1}.name`, null) !== '') {
                  elements.value = elements.value.concat([{ name: '' }])
                }
              }

              if (productData.type === PRODUCT_TYPE_WORK_DETAILED) {
                elements.value.map((el) => {
                  // Allow editing first item if created empty
                  el.isNew = elements.value.length === 1 && _get(el, 'element.name', '') === ''
                  if (props.mode === 'duplicate') {
                    el.id = null
                    el.key = nanoid()
                  }

                  return el
                })
              }
            }

            let buyPrice = _get(productData, 'buyPrice', null)
            let sellPrice = _get(productData, 'sellPrice', null)

            if (sellPrice) productData.sellPrice = Math.round(sellPrice * 100) / 100 / 100
            if (buyPrice) productData.buyPrice = Math.round(buyPrice * 100) / 100 / 100

            productData.tax = _get(productData, 'tax.id', null)
            productData.unit = _get(productData, 'unit.id', null)
            if (company.value.accountingMode !== ACCOUNTING_MODE_DISABLED) {
              productData.planItem = _get(productData, 'planItem.id', null)
            }

            Object.assign(form, _cloneDeep(productData))

            updateMargin()
          } else {
            if (props.category) {
              form.category = props.category
            }
          }

          await nextTick()
          hasLoaded.value = true
        } else {
          // Cleaning form if closing from an existing product
          if (['update', 'duplicate'].includes(props.mode) && _has(props.product, 'id')) {
            resetForm()
          }

          hasLoaded.value = false
        }
      }
    )

    const handleBuyPrice = ($event) => {
      form.buyPrice = $event
    }

    const validate = function () {
      formErrors.name = !form.name ? app.i18n.t(`products.field.error.name.required`) : false
      formErrors.sellPrice =
        form.sellPrice === null && form.type !== 'text' ? app.i18n.t(`products.field.error.sellPrice.required`) : false
    }

    const galleryModal = ref(null)
    const selectImage = (image) => {
      form.images = [].concat([...form.images], [{ ...image, ...{ key: getRandomString(8) } }])
    }

    const submitForm = async function () {
      if (hasFormErrors(formErrors)) {
        setErrorState(createNextProduct.value)
        return
      }

      try {
        setLoading(true, createNextProduct.value)

        let rawFormData = { ...form }

        let formData = JSON.parse(
          JSON.stringify(rawFormData, (key, val) => {
            if (['sellPrice', 'buyPrice'].includes(key)) {
              if (val === null) return null
              val = Math.round(val * 100 * 100) / 100
            }
            if (['tax', 'unit', 'supplier', 'planItem'].includes(key)) {
              return _get(val, 'id', val)
            }

            if (key === 'images') {
              return val.map((img) => img.id)
            }
            return val
          })
        )

        if (company.value.accountingMode === ACCOUNTING_MODE_DISABLED) {
          formData.planItem = undefined
        }

        // Reset supplies and elements to only set the right one
        formData.supplies = undefined
        formData.elements = undefined

        if (PRODUCT_TYPES_WORKS.includes(formData.type)) {
          let pos = 0
          formData[formData.type === PRODUCT_TYPE_WORK_DETAILED ? 'elements' : 'supplies'] = JSON.parse(
            JSON.stringify(
              elements.value.filter((el) => (el.element && el.element.name !== '') || (el.name && el.name !== '')),
              (key, val) => {
                if (['tax', 'unit', 'planItem'].includes(key)) {
                  return _get(val, 'id', val)
                }
                return val
              }
            )
          )
            .filter((el) => (el.element && el.element.name !== '') || (el.name && el.name !== ''))
            .map((supply) => {
              // Converts supplies before saving (avoids storing unused data if switching between PRODUCT_TYPE_WORK_DETAILED and PRODUCT_TYPE_WORK)
              if (form.type === PRODUCT_TYPE_WORK_DETAILED) {
                supply.name = undefined
                supply.position = pos
                ++pos
              }
              if (form.type === PRODUCT_TYPE_WORK) {
                supply = { name: supply.name }
              }

              return supply
            })
        }

        if (![PRODUCT_TYPE_SUPPLY, PRODUCT_TYPE_MATERIAL, PRODUCT_TYPE_MISC].includes(formData.type)) {
          formData.stock = null
          formData.manageStock = false
        } else {
          if (formData.manageStock === false) formData.stock = null
          if (formData.manageStock === true && formData.stock === null) formData.stock = 0
        }

        if (formData.type === 'text') {
          formData = _pick(formData, ['name', 'reference', 'type', 'category'])
        }

        formData.category = _get(formData, 'category.id', formData.category)

        const { data, metadata } = productId.value
          ? await $productsRepository.update(productId.value, formData, { _expand: ['images', 'category'] })
          : await $productsRepository.create(formData, { _expand: ['images', 'category'] })

        $toast.show(
          productId.value ? 'Le produit a bien été mis à jour' : 'Le produit a bien été ajouté à votre bibliothèque'
        )

        $bus.emit(productId.value ? 'update.item' : 'create.item', { type: 'product', data })

        emit('updateElement', data)

        if (_get(metadata, 'spread', 0) > 0) {
          $bus.emit('list.reload', { type: 'product' })
          /*spreadCount.value = metadata.spread
          await nextTick()
          if (spreadModal.value) {
            spreadModal.value.openModal()
          }*/
        }
        resetForm()

        if (createNextProduct.value === true) {
          if (_has(formRef.value, 'hideErrors')) {
            formRef.value.hideErrors()
            formRef.value.namedErrors = []
            formRef.value.namedFieldErrors = {}
          }

          if (editorContent.value !== null) {
            try {
              editorContent.value.$el.getElementsByClassName('ProseMirror')[0].focus()
            } catch (err) {}
          }
        } else {
          modal.closeModal()
        }
      } catch (err) {
        setErrorState(createNextProduct.value)
        console.log(err)
      } finally {
        setLoading(false, createNextProduct.value)
      }
    }

    const handleElementUpdate = (data) => {
      let element = elements.value.find((el) => el.element.id === data.id)
      if (element) {
        element.element.name = data.name
        element.element.unit = data.unit
        element.element.sellPrice = data.sellPrice
        element.element.buyPrice = data.buyPrice
      }
    }

    /*const handleSpread = async (spread = false) => {
      if (spread) {
        try {
          setLoading(true, `spread_${productId.value}`)
          await $productsRepository.update(productId.value, {}, { _expand: ['images', 'category'] })
          setLoading(false, `spread_${productId.value}`, null)
          resetForm()
          $bus.emit('list.reload', { type: 'product' })
          if (spreadModal.value) {
            spreadModal.value.closeModal()
          }
          await nextTick()
          modal.closeModal()
        } catch (err) {}
      } else {
        resetForm()
        if (spreadModal.value) {
          spreadModal.value.closeModal()
        }
        await nextTick()
        modal.closeModal()
      }
    }*/

    provide('id', productId)

    const createCategoryName = ref(null)
    const handleCreateCategory = async (name) => {
      createCategoryName.value = name
      await nextTick()
      productCategoryModal.value.openModal()
    }

    const handleCategoryCreated = ($event) => {
      form.category = $event
      if (searchCategories.value) {
        searchCategories.value.reload()
      }
    }

    const resetForm = () => {
      Object.assign(form, defaultForm({ type: props.type, defaultUnit }))
      if (editorContent.value !== null) {
        editorContent.value.updateValue('')
      }
      elements.value = props.type === PRODUCT_TYPE_WORK_DETAILED ? [defaultWorkItem()] : [{ name: '' }]
      productId.value = null
    }

    const handleCloseModal = () => {
      resetForm()
      modal.closeModal()
    }

    const deleteImage = (image) => {
      const fileIndex = form.images.findIndex((el) => el.id === image.id)
      if (fileIndex !== -1) {
        form.images.splice(fileIndex, 1)
      }
    }

    const sections = computed(() => {
      return [
        {
          name: "Prix d'achat",
          color: 'bg-red-500/80 hover:bg-red-400',
          percent: buyPricePercent.value - costsPercent.value,
          total: `${$format.currency(form.buyPrice * 100)}`,
          tooltip: `Prix d'achat`,
        },
        {
          name: 'Frais généraux',
          color: 'bg-red-400/80 hover:bg-red-300',
          percent: costsPercent.value,
          total: `${$format.currency((margins.totalCost.value - form.buyPrice) * 100)}`,
          tooltip: `Frais généraux : ${company.value.defaultCoefficient} %`,
        },
        {
          name: 'Marge nette',
          color: 'bg-green-500 hover:bg-green-400',
          percent: 100 - buyPricePercent.value,
          total: `${$format.currency(margins.margin.value * 100)}`,
          tooltip: `Marge nette : ${$format.number(margins.marginRate.value)} %`,
        },
      ]
    })

    return {
      ...modal,
      ...margins,
      can,
      minPlan,
      editorContent,
      selectedTab,
      toolbar: computed(() => (can('plan', 'products.rich_text') ? toolbar : null)),
      formRef,
      form,
      defaultPlanItem,
      planItemOptions,
      productMetadata,
      grossMarginRate,
      grossMarginRateInput,
      formErrors,
      productId,
      elements,
      fetchError,
      isLoading,
      deleteImage,
      hasErrorState,
      handleBuyPrice,
      applyMargin,
      createNextProduct,
      showExtended,
      company,
      units,
      user,
      defaultUnit,
      defaultTax,
      galleryModal,
      formatNumber,
      highlight,
      validate,
      submitForm,
      //handleSpread,
      handleCloseModal,
      selectImage,
      createProduct,
      handleWorksSellPrice,
      handleWorksBuyPrice,
      handleCreateCategory,
      handleCategoryCreated,
      editElementModal,
      editElement,
      currentElementId,
      handleElementUpdate,
      createCategoryName,
      supplierQueryParams,
      supplierType,
      buyPricePercent,
      searchCategories,
      productCategoryModal,
      createContactModal,
      spreadModal,
      spreadCount,
      PRODUCT_TYPES,
      PRODUCT_TYPES_CREATE,
      PRODUCT_TYPE_SUPPLY,
      PRODUCT_TYPE_MATERIAL,
      PRODUCT_TYPE_MISC,
      PRODUCT_TYPE_WORK,
      PRODUCT_TYPE_WORK_DETAILED,
      PRODUCT_TYPES_WORKS,
      CONTACT_TYPE_SUPPLIER,
      ACCOUNTING_MODE_DISABLED,
      sections,
      _get,
      defaultMarginRate,
    }
  },
})
