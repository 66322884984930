//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get as _get, has as _has } from 'lodash-es'
import useModal from '@/composables/modal'
import { useErrorState, useLoading } from '@/composables/loading'
import { computed, defineComponent, onMounted, useRoute } from '@nuxtjs/composition-api'
import { pluralizeType } from '@/api/resources'
import confetti from 'canvas-confetti'
import { useUser } from '@/composables/user'
import useFlags from '@/composables/useFlags'
export default defineComponent({
  props: {
    type: {
      type: String,
      default: null,
    },
    object: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { hasFlag } = useFlags()

    const modal = useModal()
    const { subscription } = useUser()

    const { isLoading, setLoading } = useLoading()
    const { hasErrorState, setErrorState } = useErrorState()

    const route = useRoute()

    onMounted(() => {
      if (subscription.value === null || _get(subscription.value, 'status', 'active') === 'expired') {
        modal.openModal()
      }
    })

    return {
      props,
      ...modal,
      subscription,
      hasFlag,
      isLoading,
      hasErrorState,
    }
  },
})
