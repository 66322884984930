//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, onMounted, nextTick } from '@nuxtjs/composition-api'
import WaveSurfer from 'wavesurfer.js'
import RecordPlugin from 'wavesurfer.js/dist/plugins/record.esm.js'

export default defineComponent({
  props: {
    record: Boolean,
    deletable: Boolean,
    audio: [Object, Blob],
  },
  setup(props, { emit }) {
    const wavesurfer = ref(null)
    const recorder = ref(null)

    const previewWave = ref()
    const audioWave = ref()

    const recording = ref(false)
    const recordTime = ref('00:00')

    const audioURL = ref(null)

    const playButtonIcon = ref(['fas', 'play'])

    const waveSurferConfig = {
      barWidth: 5,
      barRadius: 2,
      fillParent: true,
      height: 'auto',
      waveColor: 'rgb(100, 116, 139)',
      progressColor: 'rgb(102, 101, 221)', //'rgb(100, 89, 210)',
      cursorColor: 'rgb(220, 220, 220)',
    }

    function setPlayBtn() {
      playButtonIcon.value = ['fas', 'play']
    }

    function setPauseBtn() {
      playButtonIcon.value = ['fas', 'pause']
    }

    async function startRecording() {
      if (previewWave.value && !wavesurfer.value) createWaveSurfer('record')

      await nextTick()
      if (recorder.value) {
        console.log(recorder.value)
        if (recorder.value.isRecording() || recorder.value.isPaused()) {
          recording.value = false
          await nextTick()
          if (audioWave.value) audioWave.value.innerHTML = ''
          recorder.value.stopRecording()
        } else {
          await recorder.value.startRecording()
          recording.value = true
        }
      }
    }

    async function loadRecording(audio = null) {
      audioURL.value = URL.createObjectURL(audio || props.audio)
      if (audioWave.value) createWaveSurfer('preview')
    }

    const createWaveSurfer = (mode = 'record') => {
      // Destroy the previous wavesurfer instance
      if (wavesurfer.value) {
        wavesurfer.value.destroy()
      }

      // Create a new Wavesurfer instance
      wavesurfer.value = WaveSurfer.create({
        ...waveSurferConfig,
        container: mode === 'record' ? previewWave.value : audioWave.value,
        url: mode === 'record' ? undefined : audioURL.value,
      })

      if (mode === 'record') initRecorder()

      if (mode === 'preview') {
        wavesurfer.value.on('ready', (duration) => updateProgress(duration * 1000))
        wavesurfer.value.on('timeupdate', (duration) => updateProgress(duration * 1000))
        wavesurfer.value.on('pause', setPlayBtn)
        wavesurfer.value.on('play', setPauseBtn)
      }
    }

    const initRecorder = () => {
      recorder.value = wavesurfer.value.registerPlugin(
        RecordPlugin.create({
          renderRecordedAudio: false,
          scrollingWaveform: true,
          continuousWaveform: false,
        })
      )

      recorder.value.on('record-end', async (blob) => {
        emit('blob', blob)
        await loadRecording(blob)
      })

      recorder.value.on('record-progress', (time) => {
        updateProgress(time)
      })
    }

    const updateProgress = (time) => {
      // Converts milliseconds to mm:ss
      recordTime.value = [
        Math.floor((time % 3600000) / 60000), // minutes
        Math.floor((time % 60000) / 1000), // seconds
      ]
        .map((v) => (v < 10 ? '0' + v : v))
        .join(':')
    }

    onMounted(async () => {
      if (props.record) {
        recording.value = true
        await nextTick()
        await startRecording()
      } else if (props.audio) {
        await loadRecording()
      }
    })

    return {
      props,
      startRecording,
      wavesurfer,
      recording,
      recordTime,
      previewWave,
      audioWave,
      audioURL,
      playButtonIcon,
    }
  },
})
