//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    options: {
      type: Object,
      default: () => {},
    },
    isBoolean: Boolean,
    value: {
      type: [String, Boolean],
    },
    grows: {
      type: Boolean,
      default: false,
    },
    autoWidth: Boolean,
  },
  setup(props, { emit }) {
    function getValue(key) {
      if (props.isBoolean) {
        if (typeof key === 'boolean') return key
        if (key === 'false') return false
        return true
      }
      return key
    }

    function updateValue(value) {
      emit('input', getValue(value))
    }
    return { props, updateValue, getValue }
  },
})
