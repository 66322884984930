//a31cabfce911ad88f17ba1182c244105b5a506bf
import Vue from "vue"
import { library, config } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import {
  faSpinnerThird as falSpinnerThird,
  faCircleInfo as falCircleInfo,
  faArrowsRepeat as falArrowsRepeat,
  faChevronDown as falChevronDown,
  faClock as falClock,
  faPen as falPen,
  faTrashCan as falTrashCan,
  faXmark as falXmark,
  faRulerTriangle as falRulerTriangle,
  faToolbox as falToolbox,
  faPersonDigging as falPersonDigging,
  faUser as falUser,
  faHelmetSafety as falHelmetSafety,
  faGear as falGear,
  faChevronCircleRight as falChevronCircleRight,
  faEraser as falEraser,
  faPhone as falPhone,
  faEnvelope as falEnvelope,
  faLockOpen as falLockOpen,
  faPlus as falPlus,
  faSlidersUp as falSlidersUp,
  faCalendarDays as falCalendarDays,
  faLockHashtag as falLockHashtag,
  faRotateLeft as falRotateLeft,
  faCloud as falCloud,
  faCloudXmark as falCloudXmark,
  faCloudCheck as falCloudCheck,
  faCauldron as falCauldron,
  faSpellCheck as falSpellCheck,
  faRepeat as falRepeat,
  faTruckLadder as falTruckLadder,
  faCalendarDay as falCalendarDay,
  faLink as falLink,
  faListCheck as falListCheck,
  faArrowUpToLine as falArrowUpToLine,
  faArrowDownToLine as falArrowDownToLine,
  faCopy as falCopy,
  faArrowsUpDownLeftRight as falArrowsUpDownLeftRight,
  faArrowUpRightAndArrowDownLeftFromCenter as falArrowUpRightAndArrowDownLeftFromCenter,
  faCalculatorSimple as falCalculatorSimple,
  faChevronRight as falChevronRight,
  faBolt as falBolt,
  faBox as falBox,
  faFileInvoice as falFileInvoice,
  faMinus as falMinus,
  faMoneyCheck as falMoneyCheck,
  faCreditCard as falCreditCard,
  faCoins as falCoins,
  faEuroSign as falEuroSign,
  faBuildingColumns as falBuildingColumns,
  faArrowPointer as falArrowPointer,
  faChevronUp as falChevronUp,
  faPaperclip as falPaperclip,
  faMoneyBillTransfer as falMoneyBillTransfer,
  faEye as falEye,
  faPercent as falPercent,
  faFileImport as falFileImport,
  faArrowsRotate as falArrowsRotate,
  faPalette as falPalette,
  faPrint as falPrint,
  faPaperPlane as falPaperPlane,
  faCircleQuestion as falCircleQuestion,
  faRotateExclamation as falRotateExclamation,
  faPencil as falPencil,
  faPlusMinus as falPlusMinus,
  faThumbsUp as falThumbsUp,
  faThumbsDown as falThumbsDown,
  faSignature as falSignature,
  faChevronLeft as falChevronLeft,
  faCheck as falCheck,
  faMagnifyingGlass as falMagnifyingGlass,
  faDownload as falDownload,
  faHandHoldingDollar as falHandHoldingDollar,
  faFileExport as falFileExport,
  faEllipsis as falEllipsis,
  faPenField as falPenField,
  faStamp as falStamp,
  faChartGantt as falChartGantt,
  faFile as falFile,
  faBan as falBan,
  faTruck as falTruck,
  faBoxCheck as falBoxCheck,
  faCircleXmark as falCircleXmark,
  faCalendar as falCalendar,
  faClockRotateLeft as falClockRotateLeft,
  faWrench as falWrench,
  faBoxOpen as falBoxOpen,
  faShareNodes as falShareNodes,
  faEnvelopeCircleCheck as falEnvelopeCircleCheck,
  faTriangleExclamation as falTriangleExclamation,
  faCommentSms as falCommentSms,
  faEyeDropper as falEyeDropper,
  faWandMagicSparkles as falWandMagicSparkles,
  faUpload as falUpload,
  faFolderOpen as falFolderOpen,
  faFolder as falFolder,
  faHashtag as falHashtag,
  faArrowLeft as falArrowLeft,
  faReceipt as falReceipt,
  faArrowRight as falArrowRight,
  faFilePdf as falFilePdf,
  faMemoCircleInfo as falMemoCircleInfo,
  faCalendarClock as falCalendarClock,
  faMessage as falMessage,
  faNote as falNote,
  faCamera as falCamera,
  faTag as falTag,
  faLock as falLock,
  faLayerGroup as falLayerGroup,
  faLinkSlash as falLinkSlash,
  faHourglass as falHourglass,
  faCloudArrowUp as falCloudArrowUp,
  faRocket as falRocket,
  faArrowRightFromBracket as falArrowRightFromBracket,
  faArrowUpRightFromSquare as falArrowUpRightFromSquare,
  faForwardStep as falForwardStep,
  faCircleDollarToSlot as falCircleDollarToSlot,
  faBellOn as falBellOn,
  faEyeSlash as falEyeSlash
} from "@fortawesome/pro-light-svg-icons"

import {
  faCheck as farCheck,
  faSpinnerThird as farSpinnerThird,
  faUpRightFromSquare as farUpRightFromSquare,
  faMicrophone as farMicrophone,
  faTrashCan as farTrashCan,
  faArrowsRotate as farArrowsRotate,
  faLink as farLink,
  faEllipsisVertical as farEllipsisVertical,
  faLinkSlash as farLinkSlash,
  faGlobe as farGlobe,
  faChevronLeft as farChevronLeft,
  faChevronRight as farChevronRight,
  faChevronDown as farChevronDown,
  faXmark as farXmark,
  faUpload as farUpload,
  faIndustryWindows as farIndustryWindows,
  faUser as farUser,
  faEllipsis as farEllipsis,
  faDiamondTurnRight as farDiamondTurnRight,
  faPlus as farPlus,
  faTriangleExclamation as farTriangleExclamation,
  faMagnifyingGlassMinus as farMagnifyingGlassMinus,
  faMagnifyingGlassPlus as farMagnifyingGlassPlus,
  faCalculatorSimple as farCalculatorSimple,
  faPersonDolly as farPersonDolly,
  faPersonDigging as farPersonDigging,
  faMinus as farMinus,
  faImage as farImage,
  faArrowUp as farArrowUp,
  faArrowDown as farArrowDown,
  faSplit as farSplit,
  faLockOpen as farLockOpen,
  faPen as farPen,
  faCamera as farCamera,
  faWrench as farWrench,
  faFileExcel as farFileExcel,
  faCoins as farCoins,
  faClone as farClone,
  faRotateLeft as farRotateLeft,
  faNoteSticky as farNoteSticky,
  faHourglassStart as farHourglassStart,
  faSignature as farSignature,
  faEnvelope as farEnvelope,
  faFilePdf as farFilePdf,
  faArrowsUpDown as farArrowsUpDown,
  faArrowsToDottedLine as farArrowsToDottedLine,
  faMagnifyingGlass as farMagnifyingGlass,
  faBullhorn as farBullhorn,
  faCircleQuestion as farCircleQuestion,
  faSquareInfo as farSquareInfo,
  faChevronUp as farChevronUp,
  faToolbox as farToolbox,
  faFolderPlus as farFolderPlus,
  faFileExport as farFileExport,
  faBell as farBell,
  faScreenUsers as farScreenUsers,
  faBooks as farBooks,
  faArrowRight as farArrowRight,
  faPhone as farPhone,
  faBarsProgress as farBarsProgress,
  faSquareCheck as farSquareCheck,
  faClipboardListCheck as farClipboardListCheck,
  faPaperclip as farPaperclip,
  faArrowLeft as farArrowLeft,
  faTextSize as farTextSize,
  faHourglass as farHourglass,
  faAlignSlash as farAlignSlash,
  faTextSlash as farTextSlash,
  faEraser as farEraser,
  faBold as farBold,
  faItalic as farItalic,
  faStrikethrough as farStrikethrough,
  faUnderline as farUnderline,
  faAlignLeft as farAlignLeft,
  faAlignCenter as farAlignCenter,
  faAlignJustify as farAlignJustify,
  faAlignRight as farAlignRight,
  faParagraph as farParagraph,
  faH1 as farH1,
  faH2 as farH2,
  faH3 as farH3,
  faListUl as farListUl,
  faListOl as farListOl,
  faFlagCheckered as farFlagCheckered,
  faClockRotateLeft as farClockRotateLeft,
  faPaperPlane as farPaperPlane,
  faThumbsUp as farThumbsUp,
  faThumbsDown as farThumbsDown,
  faBan as farBan,
  faClock as farClock,
  faCheckDouble as farCheckDouble,
  faMessageExclamation as farMessageExclamation,
  faCalendarExclamation as farCalendarExclamation,
  faUnlock as farUnlock,
  faTruck as farTruck,
  faBoxCircleCheck as farBoxCircleCheck,
  faHelmetSafety as farHelmetSafety,
  faBlockBrick as farBlockBrick,
  faIdBadge as farIdBadge,
  faUserHelmetSafety as farUserHelmetSafety,
  faHammer as farHammer,
  faCreditCard as farCreditCard,
  faCartCircleArrowUp as farCartCircleArrowUp,
  faBox as farBox,
  faDownLeftAndUpRightToCenter as farDownLeftAndUpRightToCenter,
  faExpandWide as farExpandWide,
  faListCheck as farListCheck,
  faEyeSlash as farEyeSlash,
  faEye as farEye
} from "@fortawesome/pro-regular-svg-icons"

import {
  faLock as fasLock,
  faTriangleExclamation as fasTriangleExclamation,
  faFilePlus as fasFilePlus,
  faCircleCheck as fasCircleCheck,
  faSparkles as fasSparkles,
  faPaperPlane as fasPaperPlane,
  faFilePdf as fasFilePdf,
  faFileImage as fasFileImage,
  faPause as fasPause,
  faPlay as fasPlay,
  faCheck as fasCheck,
  faPersonDigging as fasPersonDigging,
  faToolbox as fasToolbox,
  faRulerTriangle as fasRulerTriangle,
  faEye as fasEye,
  faStar as fasStar,
  faCaretDown as fasCaretDown,
  faCaretRight as fasCaretRight,
  faPlus as fasPlus,
  faReceipt as fasReceipt,
  faUser as fasUser,
  faPen as fasPen,
  faWifiSlash as fasWifiSlash,
  faFloppyDisk as fasFloppyDisk,
  faChevronDown as fasChevronDown,
  faBolt as fasBolt,
  faFolderPlus as fasFolderPlus,
  faFolderArrowUp as fasFolderArrowUp,
  faGripDotsVertical as fasGripDotsVertical,
  faChevronUp as fasChevronUp,
  faEllipsisVertical as fasEllipsisVertical,
  faBox as fasBox,
  faFileInvoice as fasFileInvoice,
  faUserCheck as fasUserCheck,
  faWandMagicSparkles as fasWandMagicSparkles,
  faMinus as fasMinus,
  faPhone as fasPhone,
  faEnvelope as fasEnvelope,
  faEarthEurope as fasEarthEurope,
  faFileExcel as fasFileExcel,
  faFileWord as fasFileWord,
  faFileLines as fasFileLines,
  faCalendarDays as fasCalendarDays,
  faBullhorn as fasBullhorn,
  faMessageQuestion as fasMessageQuestion,
  faBarsSort as fasBarsSort,
  faBars as fasBars,
  faFolderXmark as fasFolderXmark,
  faGift as fasGift,
  faBadgePercent as fasBadgePercent,
  faCircleInfo as fasCircleInfo,
  faCircleXmark as fasCircleXmark,
  faSort as fasSort,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faCircleExclamation as fasCircleExclamation,
  faXmark as fasXmark,
  faHourglass as fasHourglass,
  faMagnifyingGlass as fasMagnifyingGlass,
  faArrowLeft as fasArrowLeft,
  faArrowRotateRight as fasArrowRotateRight,
  faChartLine as fasChartLine,
  faStopwatch as fasStopwatch,
  faHandHoldingDollar as fasHandHoldingDollar,
  faCoins as fasCoins,
  faBuildingColumns as fasBuildingColumns,
  faBookUser as fasBookUser,
  faFolders as fasFolders,
  faBasketShopping as fasBasketShopping,
  faIndustryWindows as fasIndustryWindows,
  faBook as fasBook,
  faGear as fasGear,
  faHeart as fasHeart,
  faLifeRing as fasLifeRing,
  faBuilding as fasBuilding,
  faMailbox as fasMailbox,
  faHouseCrack as fasHouseCrack,
  faUsers as fasUsers,
  faRocket as fasRocket,
  faCalculatorSimple as fasCalculatorSimple,
  faPercent as fasPercent,
  faGaugeMax as fasGaugeMax,
  faTags as fasTags,
  faFileContract as fasFileContract,
  faWallet as fasWallet,
  faFile as fasFile,
  faPalette as fasPalette,
  faFileDashedLine as fasFileDashedLine,
  faBellOn as fasBellOn,
  faExclamation as fasExclamation
} from "@fortawesome/pro-solid-svg-icons"

import {
  faCalendarClock as fatCalendarClock,
  faCalendarCheck as fatCalendarCheck
} from "@fortawesome/pro-thin-svg-icons"

import {
  faTriangleExclamation as fadTriangleExclamation,
  faLaptop as fadLaptop,
  faUserHelmetSafety as fadUserHelmetSafety,
  faCreditCard as fadCreditCard,
  faUser as fadUser,
  faRulerTriangle as fadRulerTriangle,
  faReceipt as fadReceipt,
  faBookUser as fadBookUser,
  faPersonDigging as fadPersonDigging,
  faFolders as fadFolders,
  faLightbulb as fadLightbulb,
  faGear as fadGear,
  faChartColumn as fadChartColumn,
  faFileInvoice as fadFileInvoice,
  faFileZipper as fadFileZipper,
  faCircleInfo as fadCircleInfo,
  faHandHoldingDollar as fadHandHoldingDollar,
  faFilePen as fadFilePen,
  faSignature as fadSignature,
  faCalendarClock as fadCalendarClock,
  faPaperPlane as fadPaperPlane,
  faShareFromSquare as fadShareFromSquare,
  faFilePdf as fadFilePdf,
  faBook as fadBook,
  faToolbox as fadToolbox,
  faDownToLine as fadDownToLine,
  faPenField as fadPenField,
  faSortUp as fadSortUp,
  faSortDown as fadSortDown,
  faFolder as fadFolder,
  faSackDollar as fadSackDollar,
  faFile as fadFile,
  faWandMagicSparkles as fadWandMagicSparkles,
  faArrowRightFromBracket as fadArrowRightFromBracket
} from "@fortawesome/pro-duotone-svg-icons"

import {
  faWhatsapp as fabWhatsapp
} from "@fortawesome/free-brands-svg-icons"

library.add(
  falSpinnerThird,
  falCircleInfo,
  falArrowsRepeat,
  falChevronDown,
  falClock,
  falPen,
  falTrashCan,
  falXmark,
  falRulerTriangle,
  falToolbox,
  falPersonDigging,
  falUser,
  falHelmetSafety,
  falGear,
  falChevronCircleRight,
  falEraser,
  falPhone,
  falEnvelope,
  falLockOpen,
  falPlus,
  falSlidersUp,
  falCalendarDays,
  falLockHashtag,
  falRotateLeft,
  falCloud,
  falCloudXmark,
  falCloudCheck,
  falCauldron,
  falSpellCheck,
  falRepeat,
  falTruckLadder,
  falCalendarDay,
  falLink,
  falListCheck,
  falArrowUpToLine,
  falArrowDownToLine,
  falCopy,
  falArrowsUpDownLeftRight,
  falArrowUpRightAndArrowDownLeftFromCenter,
  falCalculatorSimple,
  falChevronRight,
  falBolt,
  falBox,
  falFileInvoice,
  falMinus,
  falMoneyCheck,
  falCreditCard,
  falCoins,
  falEuroSign,
  falBuildingColumns,
  falArrowPointer,
  falChevronUp,
  falPaperclip,
  falMoneyBillTransfer,
  falEye,
  falPercent,
  falFileImport,
  falArrowsRotate,
  falPalette,
  falPrint,
  falPaperPlane,
  falCircleQuestion,
  falRotateExclamation,
  falPencil,
  falPlusMinus,
  falThumbsUp,
  falThumbsDown,
  falSignature,
  falChevronLeft,
  falCheck,
  falMagnifyingGlass,
  falDownload,
  falHandHoldingDollar,
  falFileExport,
  falEllipsis,
  falPenField,
  falStamp,
  falChartGantt,
  falFile,
  falBan,
  falTruck,
  falBoxCheck,
  falCircleXmark,
  falCalendar,
  falClockRotateLeft,
  falWrench,
  falBoxOpen,
  falShareNodes,
  falEnvelopeCircleCheck,
  falTriangleExclamation,
  falCommentSms,
  falEyeDropper,
  falWandMagicSparkles,
  falUpload,
  falFolderOpen,
  falFolder,
  falHashtag,
  falArrowLeft,
  falReceipt,
  falArrowRight,
  falFilePdf,
  falMemoCircleInfo,
  falCalendarClock,
  falMessage,
  falNote,
  falCamera,
  falTag,
  falLock,
  falLayerGroup,
  falLinkSlash,
  falHourglass,
  falCloudArrowUp,
  falRocket,
  falArrowRightFromBracket,
  falArrowUpRightFromSquare,
  falForwardStep,
  falCircleDollarToSlot,
  falBellOn,
  falEyeSlash
)

library.add(
  farCheck,
  farSpinnerThird,
  farUpRightFromSquare,
  farMicrophone,
  farTrashCan,
  farArrowsRotate,
  farLink,
  farEllipsisVertical,
  farLinkSlash,
  farGlobe,
  farChevronLeft,
  farChevronRight,
  farChevronDown,
  farXmark,
  farUpload,
  farIndustryWindows,
  farUser,
  farEllipsis,
  farDiamondTurnRight,
  farPlus,
  farTriangleExclamation,
  farMagnifyingGlassMinus,
  farMagnifyingGlassPlus,
  farCalculatorSimple,
  farPersonDolly,
  farPersonDigging,
  farMinus,
  farImage,
  farArrowUp,
  farArrowDown,
  farSplit,
  farLockOpen,
  farPen,
  farCamera,
  farWrench,
  farFileExcel,
  farCoins,
  farClone,
  farRotateLeft,
  farNoteSticky,
  farHourglassStart,
  farSignature,
  farEnvelope,
  farFilePdf,
  farArrowsUpDown,
  farArrowsToDottedLine,
  farMagnifyingGlass,
  farBullhorn,
  farCircleQuestion,
  farSquareInfo,
  farChevronUp,
  farToolbox,
  farFolderPlus,
  farFileExport,
  farBell,
  farScreenUsers,
  farBooks,
  farArrowRight,
  farPhone,
  farBarsProgress,
  farSquareCheck,
  farClipboardListCheck,
  farPaperclip,
  farArrowLeft,
  farTextSize,
  farHourglass,
  farAlignSlash,
  farTextSlash,
  farEraser,
  farBold,
  farItalic,
  farStrikethrough,
  farUnderline,
  farAlignLeft,
  farAlignCenter,
  farAlignJustify,
  farAlignRight,
  farParagraph,
  farH1,
  farH2,
  farH3,
  farListUl,
  farListOl,
  farFlagCheckered,
  farClockRotateLeft,
  farPaperPlane,
  farThumbsUp,
  farThumbsDown,
  farBan,
  farClock,
  farCheckDouble,
  farMessageExclamation,
  farCalendarExclamation,
  farUnlock,
  farTruck,
  farBoxCircleCheck,
  farHelmetSafety,
  farBlockBrick,
  farIdBadge,
  farUserHelmetSafety,
  farHammer,
  farCreditCard,
  farCartCircleArrowUp,
  farBox,
  farDownLeftAndUpRightToCenter,
  farExpandWide,
  farListCheck,
  farEyeSlash,
  farEye
)

library.add(
  fasLock,
  fasTriangleExclamation,
  fasFilePlus,
  fasCircleCheck,
  fasSparkles,
  fasPaperPlane,
  fasFilePdf,
  fasFileImage,
  fasPause,
  fasPlay,
  fasCheck,
  fasPersonDigging,
  fasToolbox,
  fasRulerTriangle,
  fasEye,
  fasStar,
  fasCaretDown,
  fasCaretRight,
  fasPlus,
  fasReceipt,
  fasUser,
  fasPen,
  fasWifiSlash,
  fasFloppyDisk,
  fasChevronDown,
  fasBolt,
  fasFolderPlus,
  fasFolderArrowUp,
  fasGripDotsVertical,
  fasChevronUp,
  fasEllipsisVertical,
  fasBox,
  fasFileInvoice,
  fasUserCheck,
  fasWandMagicSparkles,
  fasMinus,
  fasPhone,
  fasEnvelope,
  fasEarthEurope,
  fasFileExcel,
  fasFileWord,
  fasFileLines,
  fasCalendarDays,
  fasBullhorn,
  fasMessageQuestion,
  fasBarsSort,
  fasBars,
  fasFolderXmark,
  fasGift,
  fasBadgePercent,
  fasCircleInfo,
  fasCircleXmark,
  fasSort,
  fasChevronLeft,
  fasChevronRight,
  fasCircleExclamation,
  fasXmark,
  fasHourglass,
  fasMagnifyingGlass,
  fasArrowLeft,
  fasArrowRotateRight,
  fasChartLine,
  fasStopwatch,
  fasHandHoldingDollar,
  fasCoins,
  fasBuildingColumns,
  fasBookUser,
  fasFolders,
  fasBasketShopping,
  fasIndustryWindows,
  fasBook,
  fasGear,
  fasHeart,
  fasLifeRing,
  fasBuilding,
  fasMailbox,
  fasHouseCrack,
  fasUsers,
  fasRocket,
  fasCalculatorSimple,
  fasPercent,
  fasGaugeMax,
  fasTags,
  fasFileContract,
  fasWallet,
  fasFile,
  fasPalette,
  fasFileDashedLine,
  fasBellOn,
  fasExclamation
)

library.add(
  fatCalendarClock,
  fatCalendarCheck
)

library.add(
  fadTriangleExclamation,
  fadLaptop,
  fadUserHelmetSafety,
  fadCreditCard,
  fadUser,
  fadRulerTriangle,
  fadReceipt,
  fadBookUser,
  fadPersonDigging,
  fadFolders,
  fadLightbulb,
  fadGear,
  fadChartColumn,
  fadFileInvoice,
  fadFileZipper,
  fadCircleInfo,
  fadHandHoldingDollar,
  fadFilePen,
  fadSignature,
  fadCalendarClock,
  fadPaperPlane,
  fadShareFromSquare,
  fadFilePdf,
  fadBook,
  fadToolbox,
  fadDownToLine,
  fadPenField,
  fadSortUp,
  fadSortDown,
  fadFolder,
  fadSackDollar,
  fadFile,
  fadWandMagicSparkles,
  fadArrowRightFromBracket
)

library.add(
  fabWhatsapp
)

config.autoAddCss = false
Vue.component("fa-icon", FontAwesomeIcon)