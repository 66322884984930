//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/*let preventsClick = true
const preventClick = function (e) {
  console.log('clicked')
  if (preventsClick) {
    console.log('prevent default')
    e.preventDefault()
  }
}

const triggerClick = function (e) {
  console.log('focused')
  preventsClick = true
  this.click()
}*/
import { get as _get } from 'lodash-es'
import { VueTelInput } from 'vue-tel-input'
export default {
  components: { VueTelInput },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      init: false,
      localModel: null,
      validation: null,
    }
  },
  mounted() {
    this.localModel = this.context.model
    this.$nextTick(() => {
      this.init = true
    })
    /*Array.from(window.document.getElementsByClassName('vti__dropdown')).forEach((el) => {
      el.addEventListener('click', preventClick)
      el.addEventListener('focus', triggerClick)
    })*/
  },
  beforeDestroy() {
    /*Array.from(window.document.getElementsByClassName('vti__dropdown')).forEach((el) => {
      el.removeEventListener('focus', triggerClick)
    })*/
  },
  computed: {
    defaultCountry() {
      return _get(this.context, 'attributes.default-country', 'fr')
    },
  },
  methods: {
    handleInput(number, phoneObject) {
      if (_get(phoneObject, 'valid', false)) {
        this.context.model = _get(phoneObject, 'number', null)
      }
    },
    handleValidation($event) {
      this.validation = $event

      if (!this.validation.valid && this.context.model !== '') {
        //this.context.errors.push('Le numéro saisi semble invalide')
      } else {
        const index = this.context.errors.findIndex((el) => el === this.$t('layout.field.phone.error.invalid'))
        if (index !== -1) {
          this.context.errors.splice(index, 1)
        }
      }

      this.$emit('contextValidation', {
        name: this.context.name,
        isValid: this.context.localModel === '' ? true : this.validation.valid,
      })
    },

    async handleBlur() {
      if (!this.validation.valid && this.localModel !== '') {
        this.context.errors.push(this.$t('layout.field.phone.error.invalid'))
      } else {
        const index = this.context.errors.findIndex((el) => el === this.$t('layout.field.phone.error.invalid'))
        if (index !== -1) {
          this.context.errors.splice(index, 1)
        }
      }

      this.$emit('contextValidation', {
        name: this.context.name,
        isValid: this.context.localModel === '' ? true : this.validation.valid,
      })

      this.context.performValidation()
      this.context.blurHandler()

      await this.context.getValidationErrors()
    },
  },
  watch: {
    validation: function (val) {
      this.context.model = val.valid ? val.number : null
    },
  },
}
