var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Floating',{staticClass:"shrink-0",attrs:{"no-padding":"","no-auto-close":"","wrap":"","placement":"bottom-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isOpen = ref.isOpen;
return [_c('Button',{ref:"btn",staticClass:"!px-1 mr-1",attrs:{"minimal":"","icon-before":['fas', 'sparkles'],"icon-before-class":"!text-lg"}})]}},{key:"menu",fn:function(ref){
var close = ref.close;
return [_c('div',{staticClass:"w-[90vw] max-w-sm h-[80vh] max-h-[500px] text-sm flex flex-col"},[_c('div',{ref:"scrollContainer",staticClass:"overflow-y-auto p-3 min-h-0 h-full"},[_c('div',{staticClass:"space-y-4 pb-3"},_vm._l((_vm.messages),function(message){return _c('AiMessage',{key:message.key,attrs:{"message":message}})}),1)]),_vm._v(" "),_c('div',{staticClass:"flex min-h-[48px] shrink-0 relative bg-gray-50 border-t border-gray-200 rounded-b-lg"},[(_vm.recording)?_c('div',{staticClass:"w-full p-2",class:_vm.formAudioRecord ? 'pr-14' : ''},[_c('AudioBlock',{staticClass:"w-full",attrs:{"record":""},on:{"blob":function($event){_vm.formAudioRecord = $event},"delete":function () {
                _vm.recording = false
                _vm.formAudioRecord = null
              }}})],1):_c('ResizeableTextarea',{staticClass:"w-full"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formText),expression:"formText"}],staticClass:"resize-none w-full h-full min-h-[44px] pl-2 pt-2 pr-24 bg-transparent focus:outline-none",attrs:{"placeholder":"Demandez à l'IA Costructor..."},domProps:{"value":(_vm.formText)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.sendMessage.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.formText=$event.target.value}}})]),_vm._v(" "),_c('div',{staticClass:"absolute top-2 right-2 flex justify-end"},[(!_vm.recording)?_c('Button',{staticClass:"!px-3 mr-2 bg-white",attrs:{"outline":"","icon-before":['far', 'microphone'],"icon-before-class":"m-0"},on:{"clicked":_vm.newRecording}}):_vm._e(),_vm._v(" "),(!_vm.recording || _vm.formAudioRecord)?_c('Button',{staticClass:"!px-2.5",attrs:{"color":"brand","disabled":!(_vm.formAudioRecord || _vm.formText),"icon-before":['fas', 'paper-plane'],"icon-before-class":"m-0","loading":_vm.isLoading('ia_message'),"error":_vm.hasErrorState('ia_message')},on:{"clicked":_vm.sendMessage}}):_vm._e()],1)],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }