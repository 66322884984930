import StarterKit from '@tiptap/starter-kit'
import Paragraph from '@tiptap/extension-paragraph'
import { mergeAttributes } from '@tiptap/vue-2'
import Underline from '@tiptap/extension-underline'
import Text from '@tiptap/extension-text'
import TextAlign from '@tiptap/extension-text-align'
import History from '@tiptap/extension-history'
import Document from '@tiptap/extension-document'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'
import { useContext } from '@nuxtjs/composition-api'

const ParagraphDocument = Document.extend({
  content: 'paragraph',
})

const toolbarItems = {
  unsetTextAlign: { method: 'unsetTextAlign', icon: ['far', 'align-slash'] },
  unsetAllMarks: { method: 'unsetAllMarks', icon: ['far', 'text-slash'] },
  clearNodes: { method: 'clearNodes', icon: ['far', 'eraser'] },
  // Font styles
  bold: { isActiveArgs: ['bold'], method: 'toggleBold', icon: ['far', 'bold'] },
  italic: { isActiveArgs: ['italic'], method: 'toggleItalic', icon: ['far', 'italic'] },
  strike: { isActiveArgs: ['strike'], method: 'toggleStrike', icon: ['far', 'strikethrough'] },
  underline: { isActiveArgs: ['underline'], method: 'toggleUnderline', icon: ['far', 'underline'] },
  // Text alignment
  textLeft: {
    isActiveArgs: [{ textAlign: 'left' }],
    method: 'setTextAlign',
    methodArgs: 'left',
    icon: ['far', 'align-left'],
  },
  textCenter: {
    isActiveArgs: [{ textAlign: 'center' }],
    method: 'setTextAlign',
    methodArgs: 'center',
    icon: ['far', 'align-center'],
  },
  textJustify: {
    isActiveArgs: [{ textAlign: 'justify' }],
    method: 'setTextAlign',
    methodArgs: 'justify',
    icon: ['far', 'align-justify'],
  },
  textRight: {
    isActiveArgs: [{ textAlign: 'right' }],
    method: 'setTextAlign',
    methodArgs: 'right',
    icon: ['far', 'align-right'],
  },
  // Text nodes
  p: { isActiveArgs: ['paragraph'], method: 'setParagraph', icon: ['far', 'paragraph'] },
  h1: {
    isActiveArgs: ['heading', { level: 1 }],
    method: 'toggleHeading',
    methodArgs: { level: 1 },
    icon: ['far', 'h1'],
  },
  h2: {
    isActiveArgs: ['heading', { level: 2 }],
    method: 'toggleHeading',
    methodArgs: { level: 2 },
    icon: ['far', 'h2'],
  },
  h3: {
    isActiveArgs: ['heading', { level: 3 }],
    method: 'toggleHeading',
    methodArgs: { level: 3 },
    icon: ['far', 'h3'],
  },
  generate: {
    component: 'TextEditorToolbarGenerateButton',
  },
  link: {
    component: 'TextEditorToolbarLinkButton',
  },
  color: {
    component: 'TextEditorToolbarColorButton',
  },
  fontSize: {
    component: 'TextEditorToolbarFontSizeButton',
  },
  image: {
    component: 'TextEditorToolbarImageButton',
  },
  // Lists
  ul: { isActiveArgs: ['bulletList'], method: 'toggleBulletList', icon: ['far', 'list-ul'] },
  ol: { isActiveArgs: ['orderedList'], method: 'toggleOrderedList', icon: ['far', 'list-ol'] },
}

const textExtensions = [
  StarterKit,
  Paragraph.extend({
    parseHTML() {
      return [{ tag: 'div' }]
    },
    renderHTML({ HTMLAttributes }) {
      return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
    },
  }),
  Underline.configure({
    HTMLAttributes: {
      class: 'wysiwyg-parent',
    },
  }),
  Text,
  TextStyle,
  Color,
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
]

const inlineExtensions = [
  ParagraphDocument,
  Paragraph,
  History,
  Text,
  TextStyle,
  Color,
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
]

export const useToolbar = (groups) => {
  const toolbar = groups.map(
    (items) =>
      items.reduce((arr, key) => {
        arr.push(toolbarItems[key])
        return arr
      }, []),
    []
  )

  return { toolbar, inlineExtensions, textExtensions }
}

export const usePlaceholders = () => {
  const { app } = useContext()

  const transformEmailPlaceholders = (content) => {
    const parser = new DOMParser()
    let doc = parser.parseFromString(content, 'text/html')

    for (let el of doc.querySelectorAll('span[data-type="email-content-placeholder"]')) {
      if (el.dataset.field) {
        el.replaceWith(`#${el.dataset.field}#`)
      }
    }

    return doc.body.innerHTML
  }

  const parseEmailPlaceholders = (content) => {
    return content.replaceAll(/#([^#]*)#/g, (matches, field) => {
      let message = app.i18n.t(`emails.placeholder.${field}`) || field
      return `<span data-field="${field}" data-type="email-content-placeholder">${message}</span>`
    })
  }

  return { transformEmailPlaceholders, parseEmailPlaceholders }
}
